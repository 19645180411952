import React, {useState} from "react";
import useUserAccess from "../hooks/useUserAccess";
import {Button, TextInputField} from "evergreen-ui";
import {MdPassword} from "react-icons/md";
import useNotification from "../hooks/useNotification";


export default function PasswordResetForm(){
    const { reset, editReset, resetPassword } = useUserAccess();
    const { showNotification, showError } = useNotification();
    const [spin, setSpin ] = useState(false)

    const submit = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSpin(true)
        await resetPassword()
            .then(message=>showNotification("Success!", message))
            .catch(err => showError(err))
            .finally(()=>setSpin(false))
    }
    const change = (e:React.ChangeEvent<HTMLInputElement>) => {
        editReset(e.target.name, e.target.value)
    }
    return <form onSubmit={submit} className={"w-full h-max rounded-3xl grid bg-white-500 dark:bg-black-100 p-[1rem] shadow-md max-w-md ml-auto mr-auto"}>
        <h1 className={"text-xl font-bold"}>Reset Password</h1>
        <TextInputField label={"Old Password"} name={"oldPassword"} value={reset.oldPassword} onChange={change} />
        <TextInputField label={"New Password"} name={"newPasswd"} value={reset.newPasswd} onChange={change} />
        <TextInputField label={"Confirm Password"} name={"confirm"} value={reset.confirm} onChange={change} />
        <Button className={"primary-btn"} isLoading={spin} disabled={spin} appearance={"primary"} iconBefore={MdPassword}>
            Reset Password
        </Button>
    </form>
}

