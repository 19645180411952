import { path } from '../Types/Types';
import {
    AddToArtifactIcon,
    HomeIcon,
    ShopIcon,
    ShoppingCartIcon,
    LogInIcon,
    PersonIcon,
    LogOutIcon,
    SettingsIcon, CogIcon, RocketIcon
} from 'evergreen-ui'
import { isLoggedIn } from "./functions";
import { BsDiamondFill } from 'react-icons/bs';
import {MdDeliveryDining} from "react-icons/md";
import {RiAccountCircleFill} from "react-icons/ri";
import {FaHandshake, FaStore} from 'react-icons/fa';
import {GoSignOut} from "react-icons/go";


const access = () => isLoggedIn() ? {name: "Profile", path: "profile", icon: PersonIcon} :
    {name: "Login/Signup", path: "auth", icon: LogInIcon}

export const PagePaths:path[] = [
    {name: "HOME", path: "", icon: HomeIcon},
    // {name: "Jara Store", path: "jara-store", icon: ShopIcon},
    // {name: "Cart", path: "cart", icon: ShoppingCartIcon },
    {name: "SELL", path: "sell", icon: ShopIcon},
    {name: "SUBSCRIPTION", path: "subscriptions"},
    {name: "ACCOUNT", path: "my-account", icon: PersonIcon},
    {name: "SETTINGS", path: "settings", icon: SettingsIcon},
    localStorage.getItem("minisToreken") ?
        {name: "LOGOUT", path: "settings/logout", icon: LogOutIcon}:
        {name: "LOGIN/SIGNUP", path: "auth", icon: LogInIcon}
]

export const sellPagePaths:path[] = [
    {name: "Upload", path: "", icon: AddToArtifactIcon},
    {name: "My products", path: "my-products", icon: FaStore },
    {name: "Offers", path: "store-offers", icon: FaHandshake },
    {name: "Subscriptions", path: "subscriptions", icon: BsDiamondFill},
]

export const dashboardPaths: path[] = [
    // {name: "Orders", path: "", icon: MdDeliveryDining },
    {name: "Offers", path: "", icon: FaHandshake},
    // {name: "Account", path: "account", icon: RiAccountCircleFill },
    // {name: "Logout", path: "logout", icon: LogOutIcon}
]
export const settingsPagePaths: path[] = [
    {name: "Settings", path: "", icon: CogIcon},
    {name: "Logout", path: "logout", icon: GoSignOut},
]
