import { Card, Image } from 'evergreen-ui'
import React, {startTransition} from 'react'
import { useNavigate } from 'react-router-dom'
import { categoryObj } from '../Types/Types';

export interface categoryIcon {_id: string, name: string, icon: string}

const CategoryIcon = ({category}: {category: categoryObj} ) => {
    const navigate = useNavigate();

    const getPath = (name:string) => name.replace(/\W/g, '').toLowerCase()
    const goto = () => startTransition(()=>navigate(`/category/${getPath(category.category)}`, {preventScrollReset: false}));
  return (
    <Card onClick={goto} elevation={0} className='w-full !rounded-md text-center transition-all cursor-pointer hover:scale-[0.98] min-h-[12vmax] h-max bg-white-500 dark:bg-black-200 dark:hover:bg-black-100 grid grid-flow-row justify-around'>
        <div className={"m-auto"}>
            <div className='w-full h-[7vmax] '>
              <Image className='w-[80%] m-auto h-full object-contain' src={category.image} alt={category.category} />
            </div>
            <h1 className='leading-[5vh] h-[5vh] font-bold text-[0.9rem] break-before-auto text-black-50 dark:text-white-500 '>{category.category.toUpperCase()}</h1>
        </div>
    </Card>
  )
}

export default CategoryIcon