import {useEffect, useRef} from "react";
import useInsight from "./useInsight";


export default function useImpression(productId: string){
    const divRef = useRef(null);
    const { addImpression } = useInsight();

    useEffect(() => {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5 // whole target must be visible
        }
        const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver)=>{
            entries.forEach(async (entry)=>{
                if(entry.isIntersecting) {
                    await addImpression(productId)
                        .then(()=>{
                        })
                        .catch(_err => {})
                    observer.unobserve(entry.target)
                }
            })
        }
        const observer = new IntersectionObserver(handleIntersection, observerOptions)
        if(divRef.current){
            observer.observe(divRef.current)
        }
        const divRefCurrent = divRef.current
        return ()=>{
            if(divRefCurrent){
                observer.unobserve(divRefCurrent)
            }
        }
    }, [addImpression, productId]);

    return { divRef }
}

