import {StarIcon} from "evergreen-ui";
import React from "react";

export default function StarBtn({star, count, setCount}: { star: number, count: number, setCount?: () => void }) {

    return <button disabled={setCount === undefined}
                   className={"mt-auto mb-auto w-[40px] h-[40px] text-center text-2xl rounded-md bg-transparent hover:shadow-lg hover:scale-[1.01] transition-all "}
                   onClick={setCount}>
        <StarIcon style={{color: star <= count ? "gold" : "grey"}} className={`text-[22px] m-auto`}/>
    </button>
}


