import React from 'react'
import Slider from 'react-slick'
import {BannerAd} from '../Types/Types'
import img2 from '../assets/images/perfect.webp'
import img1 from '../assets/images/cartBanner.webp'
import {getRandomId} from '../utils/functions';
import ShowBannerAd from '../components/ShowBannerAd'
import HomeSearchBar from "../components/HomeSearchBar";

const TopCarousel: React.FC = () => {
    let settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        centerMode: false,
        autoplay: true,
    }
    let sample: BannerAd[] = [{_id: getRandomId(), image: img1, path: ""}, {_id: getRandomId(), image: img2, path: ""}]

    return <div className='top-carousel w-full m-auto h-max block overflow-x-hidden bg-primary-300 '>
        <Slider className={"overflow-hidden outline-dashed"}  {...settings}>
            {sample.map(ad => <ShowBannerAd ad={ad} key={ad._id}/>)}
        </Slider>
        <HomeSearchBar className={"hidden md:flex absolute max-w-md mt-auto mb-auto translate-y-1/2 top-0 bottom-0 left-10"}/>
    </div>
}

export default TopCarousel