import React, {useEffect} from 'react'
import Page from '../components/Page'
import TopCarousel from '../MajorComponents/TopCarousel'
import Categories from '../MajorComponents/Categories'
import siteData from "../store/siteData";

const HomePage = () => {
    const { suggestions, getSiteData} = siteData();

    useEffect(() => {
        // fetch suggestions keywords
        if (suggestions.length < 1) getSiteData().then(() =>{})
    }, [getSiteData, suggestions.length]);

    return (
        <Page>
            <TopCarousel/>
            <Categories/>
        </Page>
    )
}

export default HomePage