import React, {startTransition} from 'react'
import { path } from '../Types/Types'
import { IconButton, Text } from 'evergreen-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from '../utils/colors'
import inStore from "../store/InStore";

const PcNavBtn = ( { thisPath, hide } : { thisPath: path, hide?: boolean}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { cart } = inStore();

  const handleClick = () => startTransition(()=>navigate(`/${thisPath.path}`))

  return (
    <div onClick={handleClick} className='bg-black-50 dark:bg-primary-500 cursor-pointer hover:bg-black-100 min-w-[10vmin] h-[2.5em] leading-[2.5em] pl-2 pr-2 rounded-md flex'>
      <p className={`${!hide ? "hidden" : "block"} fold-bold md:block m-auto lg:block text-white-500`} >{thisPath.name}</p>
      {(thisPath.path.includes("cart") && cart.length > 0) &&
          <p className={"absolute top-0 rounded-full text-white-500 leading-[20px] text-center bg-black-50 h-[20px] w-[20px] "}>
            {cart.length}
          </p>
      }
    </div>
  )
}

export default PcNavBtn