import {FetchedReview} from "../Types/Types";
import StarBtn from "./StarBtn";


export default function ShowReview({review}:{review: FetchedReview}){
    const hiddenName = () => {
        return review.name.split("").reduce((acc, curr,i)=>{
            if(i === 0) return  acc += curr
            if(curr === " ")return acc += curr
            return  acc + "*"
        })
    }

    return <div className={"w-full bg-white-500 dark:bg-black-50 rounded-lg p-[0.5rem]"}>
        <span className={"w-full max-w-sm flex flex-wrap text-left"}>
            <p className={"text-[1.2rem] mt-auto mb-auto"}>{hiddenName()}</p>
            <span className={""}>{[1,2,3,4,5].map(star=><StarBtn key={star} star={star} count={review.rating} />)}</span>
        </span>
        <p>{review.review}</p>
    </div>
}

