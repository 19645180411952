import Page from "../components/Page";
import useSiteDataStore from "../store/siteData";
import ShowSubscription from "../components/ShowSubscription";
import {useEffect} from "react";
import useNotification from "../hooks/useNotification";
import BubbleSpinner from "../components/BubbleSpinner";


export default function SubscriptionsPage(){
    const { plans, getSiteData } = useSiteDataStore();
    const { showError } = useNotification();

    useEffect(() => {
        if(plans.length < 1) getSiteData().catch(err => showError(err))
    }, [getSiteData, plans.length, showError]);

    return <Page>
        <div className={"w-[95%] m-auto min-h-[calc(100vh-3em)]"}>
            <div className={"w-full h-full md:min-h-[60vh] bg-white-400 dark:bg-black-400 m-aut0 gap-[0.5rem] p-[0.5rem] flex flex-row flex-wrap "}>
                {plans.length < 1 && <BubbleSpinner />}
                {plans.map((plan, i)=><ShowSubscription index={i} plan={plan} key={plan._id} />)}
            </div>
        </div>
    </Page>
}

