import {OfferProps} from "./ShowOfferToSeller";
import {Button, Card, IconButton, PhoneIcon, TextInputField} from "evergreen-ui";
import {EmailIcon} from "react-share";
import {comma} from "../utils/functions";
import {useState} from "react";
import useOffers from "../hooks/useOffers";
import useNotification from "../hooks/useNotification";


export default function ShowOfferToBuyer({ offer }:OfferProps){
    const [spin, setSpin] = useState({
        spin1: false, spin2: false, spin3: false
    })
    const { showNotification, showError } = useNotification();
    const [newOffer, setOffer] = useState(offer.offers.at(-1)?.counterOffer??0);
    const { counterOffer, acceptOffer, rejectOffer } = useOffers();

    const proposeOffer = async () => {
        let hold_offer = { offer: newOffer, counterOffer: 0};
        setSpin(p => ({...p, spin1: true}))
        await counterOffer(hold_offer, offer._id, offer.offers.length)
            .then(message=>showNotification("Success!", message))
            .catch(err=>showError(err))
            .finally(()=>setSpin(p => ({...p, spin1: false})))
    }
    const sellerPrice = offer.offers.at(-1)?.counterOffer ? `Ksh.${comma(offer.offers[offer.offers.length-1].counterOffer)}` : "waiting..."

    const acceptCounterOffer = async() => {
        setSpin(p => ({...p, spin3: true}))
        await acceptOffer(offer._id)
            .then(message=>showNotification("Success!", message))
            .catch(err=>showError(err))
            .finally(()=>setSpin(p => ({...p, spin3: true})))
    }
    const rejectCounterOffer = async () => {
        setSpin(p => ({...p, spin3: true}))
        await rejectOffer(offer._id)
            .then(message=>showNotification("Success!", message))
            .catch(err=>showError(err))
            .finally(()=>setSpin(p => ({...p, spin3: false})))
    }

    return <Card elevation={2} className={"w-full pb-1 bg-white-500 dark:bg-black-100 grid gap-[0.5rem]"}>
        <div className={"w-full"}>
            <img className={"w-full h-[5rem] object-cover"} src={offer.product.images[0]} alt={""} />
        </div>
        <div className={"w-[95%] m-auto"}>
            <span className={"flex justify-between flex-wrap"}>
                <h1>{offer.buyer.name}</h1>
                <span className={"flex gap-[0.5rem] justify-around"}>
                    <IconButton width={25} height={25} icon={PhoneIcon} />
                    <IconButton width={25} height={25} icon={EmailIcon} />
                </span>
            </span>
            <span className={"grid"}>
                <i>Set Price: Ksh.{comma(offer.product.price)}</i>
                <i>Seller's Offer: {sellerPrice}</i>
            </span>
            {(!offer.accepted && !offer.rejected) && <span className={""}>
                <span className={"w-full flex gap-[0.5rem] "}>
                    <TextInputField value={newOffer} onChange={(e:any)=>setOffer(e.target.value)} type={"number"} label={'Counter Offer'} />
                    <Button isLoading={spin.spin1} disabled={spin.spin1 || !offer.offers[offer.offers.length-1].counterOffer} onClick={proposeOffer} appearance={"primary"}
                            className={"mt-auto mb-auto primary-btn"} >
                        Make Offer
                    </Button>
                </span>
                 <span className={"grid grid-cols-2 gap-[0.5rem]"}>
                    <Button onClick={acceptCounterOffer}
                            disabled={spin.spin2 || !offer.offers[offer.offers.length - 1].counterOffer}
                            appearance={"primary"}>
                        Accept
                    </Button>
                    <Button onClick={rejectCounterOffer}
                            disabled={spin.spin3 || !offer.offers[offer.offers.length - 1].counterOffer}
                            intent={'danger'} appearance={"primary"}>
                        Reject
                    </Button>
                </span>
            </span>}
            {offer.accepted && <div className={"w-full pl-2 text-white-500 h-[2.5em] leading-[2.5em] bg-green-700"} >Offer Accepted</div>}
            {offer.rejected && <div className={"w-full pl-2 text-white-500 h-[2.5em] leading-[2.5em] bg-primary-500"} >Offer Rejected</div>}
        </div>
    </Card>
}

