import { create } from "zustand";
import { siteCategories } from '../utils/data';
import sendReq from "../utils/axios";
import {orderAddress, subscriptionModel} from '../Types/Types';
import {categoryObj} from "../Types/Types";


interface locationObj {
    County: string;
    Constituency: string;
    Ward: string;
}
interface siteDataStoreObj {
    categories: categoryObj[];
    plans: subscriptionModel[];
    userPlan: any | undefined;
    locations: locationObj[];
    suggestions: string[];

    getSiteData: ()=>Promise<Error>;
    getLocations: ()=>Promise<void>;
}

const useSiteDataStore = create<siteDataStoreObj>((set, get)=>({
    categories: siteCategories,
    plans: [],
    userPlan: {},
    locations: [],
    suggestions: [],

    getLocations: async ()=>{
        await sendReq.get("/locations")
            .then(({data})=>set(state=>({...state, locations: data})))
            .catch(err=>{})
    },
    getSiteData: ()=>{
        return new Promise(async(resolve, reject) => {
            await sendReq.get('/site-data')
            .then(({data})=>{
                set(state=>({...state, plans: data.plans, userPlan: data.userPlan,
                suggestions: data.suggestions}))
            })
                .then(async()=>{
                    // await get().getLocations().catch(err=>{
                    //     throw new Error(err)
                    // })
                })
            .catch(err => resolve(err))
        })
    }
}))

export default useSiteDataStore
