import {ProductObj} from "../Types/Types";

export function getRandomId(): string {
    return Array.from({ length: 8 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'[Math.floor(Math.random() * 62)]).join('');
}

export const isLoggedIn = (): Boolean => {
    let userToken = localStorage.getItem("minisToreken");
    return userToken === null;
}

export function getLabel(inputString: string): string {
    const words = inputString.match(/[A-Z]?[a-z]+/g);
    if (!words)return inputString.toUpperCase();
    const capitalizedWords = words.map(word => word[0].toUpperCase() + word.slice(1));
    return capitalizedWords.join(" ").toUpperCase();
}

export const fixCategory = (category: string) => category.replace(/\W/g, '').toLowerCase();

export const fixPhone = (phone: string | undefined) => {
    if(phone && !phone.startsWith("+254")) return "+254"+phone.split("").reverse().join("").substring(0, 9).split("").reverse().join("")
    return phone;
}

export function comma(value: number|string) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function pickProperty(properties: {property:string, value:any}[], property:string){
    let gotten = properties.find(p => p.property.toLowerCase() === property.toLowerCase());
    if(!gotten)return {property: "", value: ""}
    return gotten;
}

export function redirectToPayment(url: string){
    let link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function htmlIt(sentence:string){
    return sentence.replace(/\r\n|\r|\n/g, "@")
}

function test(input:string, characters:string) {
    const pattern = new RegExp(`.*${characters.toLowerCase().split('').join('.*')}.*`);
    return pattern.test(input.toLowerCase());
}
export const filterFunctions: {[key:string]: (products:ProductObj[], value:any)=>ProductObj[] } = {
    minPrice: (products, value)=>products.filter(p => p.price >= value),
    maxPrice: (products, value)=>products.filter(p => p.price <= value),
    subCategory: (products, value)=>products.filter(p => p.subCategory?.toLowerCase() === value.toLowerCase()),
    location: (products, value)=>products.filter(p => test(value, p.location.county) || test(value, p.location.ward))

}

