import React from 'react'
import {path} from '../Types/Types';
import LeftPanelButton from '../components/LeftPanelButton'
import MobileDropDown from "../components/MobileDropDown";

interface props extends React.HTMLAttributes<HTMLTableSectionElement> {
    paths: path[]
}

const LeftPanel = ({paths, ...rest}: props) => {
    return (
        <>
          <section {...rest}
                   className='hidden md:grid w-[200px] grid-flow-row  auto-rows-max gap-1 h-full bg-white-500 pt-[0.2rem] dark:bg-black-400 float-left !sticky !top-[3em]  '>
              {paths.map(path => <LeftPanelButton path={path} key={path.name}/>)}
          </section>
            <section className={"w-full h-[3em] md:hidden sticky top-[3em] pl-1 leading-[3.5em] z-[300] shadow-md bg-white-500"}>
                <MobileDropDown pathFiller={"."} paths={paths} />
            </section>
        </>
    )
}

export default LeftPanel;