import {path} from "../Types/Types";
import {startTransition, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import MenuDivider from "evergreen-ui/types/menu/src/MenuDivider";
import {MdArrowDropDown} from "react-icons/md";


interface props {
    paths: path[];
    position?: string;
    pathFiller?:string;
}

export default function MobileDropDown({ paths, position, pathFiller }:props){
    const [drop, setDrop] = useState(false)
    const navigate = useNavigate();

    const hide = () => setTimeout(()=>setDrop(false), 250)
    const goto = (path: string) => {
        setDrop(false)
        startTransition(()=>navigate(`${pathFiller??""}/${path}`))
    }

    return <>
        <button className={"text-white-500 bg-black-50 w-[70px] h-[2.5em] m-auto rounded-md leading-[2.5em] pr-2 pl-2"} onClick={()=>setDrop(!drop)}>
            <span className={"flex"}><MdArrowDropDown className={"m-auto"} /><p>Menu</p></span>
        </button>
        {drop && <span
            className={`absolute w-[200px] ${position} top-[3em] gap-[0.5rem] p-[0.2rem] bg-white-300 grid grid-flow-row `}>
            {paths.map((path, i) =>
                <button onClick={()=>startTransition(()=>goto(path.path))} data-aos={'fade-in'} key={i}
                        className={"transition-all active:bg-primary-500 hover:bg-primary-300 hover:text-white-500 rounded-md h-[2.5em] leading-[2.5em] bg-white-500"}>
                    {path.name}
                </button>)
            }
        </span>}
    </>
}

