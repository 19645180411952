import { create } from "zustand";
import {UserProfile} from "../Types/Types";
import sendReq from "../utils/axios";
import React from "react";





interface useUserHook {
    userProfile: UserProfile;
    reset: {[key:string]:string,oldPassword: string, newPasswd: string, confirm: string};
    userToken: ()=>string | null;
    userIsLoggedIn: ()=>boolean;
    clientId: string | undefined;

    getClientId: ()=>Promise<string>;
    logout: ()=>Promise<boolean>;
    editReset: (field: string, value:string) => void;
    resetPassword: ()=>Promise<string>;
    editProfile: (field: string, value: string|Blob) => void;
    updateUserProfile: ( form: React.FormEvent<HTMLFormElement>, old: UserProfile) => Promise<string>;
    getLocalToken: () => void;
    saveUserToken: (token:string)=>void;
    getUserProfile: () => Promise<void>;
}
// TODO: rename token to correct business name

const useUserAccess = create<useUserHook>((set, get)=>({
    userProfile: { firstName: "", secondName: "", userName: "", phone: "", whatsapp: "", email: "", idNumber: "" },
    reset: { oldPassword: "", newPasswd: "", confirm: ""},
    userToken: ()=>localStorage.getItem("minisToreken"),
    userIsLoggedIn: () => !!get().userToken(),
    clientId: undefined,

    getClientId: ()=>{
        return new Promise(async(resolve, reject)=>{
            await sendReq.get("/client-Id")
        })
    },
    logout: ()=>{
        return new Promise((resolve, reject)=>{
            localStorage.removeItem("minisToreken")
            set(prev=>({...prev, userToken: ()=>localStorage.getItem("minisToreken")}))
            resolve(true)
        })
    },
    editReset: (field, value) => {
        let reset = get().reset;
        reset[field] = value;
        set(prev=>({...prev, reset: reset}))
    },
    resetPassword: ()=>{
        return new Promise(async(resolve,reject)=>{
            await sendReq.post("/user-reset-password", get().reset)
                .then(({data})=>resolve(data.message))
                .catch(err=>reject(err))
        })
    },
    editProfile: (field, value)=>{
        let profile = get().userProfile;
        profile[field] = value;
        set(prev=>({...prev, userProfile: profile}))
    },
    updateUserProfile: (form, old)=>{
        return new Promise(async(resolve, reject)=>{
            let reqForm = new FormData(form.currentTarget);
            let different = 1;
            for(let key of Object.keys(get().userProfile)){
                if(get().userProfile[key] !== old[key]){
                    different *= 0
                    break;
                }
                // reqForm.append(key, get().userProfile[key])
            }
            if(different === 1) resolve("Nothing has changed!");
            await sendReq.post("/update-user-details", get().userProfile)
                .then(({data})=>resolve(data.message))
                .catch(err => reject(err))
        })
    },
    getLocalToken:()=>{
        set(state=>({...state, userToken: ()=>localStorage.getItem("minisToreken")}))
    },
    saveUserToken: (token) => {
        localStorage.setItem("minisToreken", token);
        get().getLocalToken()
    },
    getUserProfile: ()=>{
        return new Promise(async(_resolve, reject)=>{
            await sendReq.get("/user-profile")
                .then(({data})=>{
                    set(prev=>({...prev, userProfile: data}))
                })
                .catch(err=>reject(err))
        })
    }
}))

export default useUserAccess;
