import {useNavigate} from "react-router-dom";
import {startTransition} from "react";
import logoIcon from '../assets/images/logoIcon.png'

export default function NavLogo(){
    const navigate = useNavigate();

    const goHome = () => startTransition(()=>navigate("/"))

    return <div onClick={goHome} className={"w-max flex cursor-pointer transition-all active:scale-[0.9] hover:scale-[0.95] leading-normal pl-2 h-[3em] "}>
        <img className={"w-full h-full m-auto"} src={logoIcon} alt={"logoIcon"}/>
    </div>
}

