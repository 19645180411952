import {Button, EnvelopeIcon, TextInputField} from "evergreen-ui";
import React, {useEffect, useState} from "react";
import useOffers from "../hooks/useOffers";
import productStore from "../store/productStore";
import useNotification from "../hooks/useNotification";


export default function SubmitYourOfferForm({ hideWidg }:{hideWidg: ()=>void}){
    const [spin, setSpin] = useState(false);
    const [offerPrice, setOfferPrice] = useState<number>();
    const { viewProduct } = productStore();
    const { postInitialOffer } = useOffers();
    const { showNotification, showError } = useNotification();

    useEffect(() => {
        try {
            window.scrollTo({top: 0})
            document.body.style.overflow = "hidden";
        } catch (err){

        }
        return(()=>{
            document.body.style.overflow = "auto";
        })
    }, []);

    const submitInitialOffer = async (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSpin(true)
        if(offerPrice && viewProduct) await postInitialOffer(viewProduct, Number(offerPrice))
            .then(message=>showNotification("Success!", message))
            .catch(err => showError(err))
            .finally(()=>setSpin(false))
    }

    return <form onSubmit={submitInitialOffer} className={"fixed w-full h-[calc(100vh-3em)] backdrop-blur bg-[rgba(255,255,255, 0.3)] dark:bg-[rgba(0,0,0,0.3)] top-0 left-0 bottom-0 p-2 drop-shadow-lg bg-white-500 z-[100] flex"}>
        <div className={"w-full h-max max-w-md p-4 bg-white-500 dark:bg-black-50 m-auto grid gap-[0.5rem] shadow-lg auto-rows-max"}>
            <h1 className={"text-2xl"}>Submit your offer</h1>
            <TextInputField className={"dark:text-white-500"} value={offerPrice} onChange={(e:any)=>setOfferPrice(e.target.value)} type={"number"} required={true} min={1} label={"Place your offer"}
                            description={"You can propose an amount to the seller and they'll either accept or deny"}/>
            <Button isLoading={spin} iconAfter={EnvelopeIcon} appearance={"primary"} className={"primary-btn mb-auto mt-auto"}>
                Submit Offer
            </Button>
            <Button className={"primary-btn-red text-red-500 dark:!text-white-500 "} intent={'danger'} onClick={hideWidg} type={'button'} >
                Cancel
            </Button>
        </div>
    </form>
}

