import React from 'react'
import CategoryIcon from '../components/CategoryIcon'
import useSiteDataStore from '../store/siteData'
import HomeSearchBar from "../components/HomeSearchBar";

const Categories = () => {
    const {categories} = useSiteDataStore();

    return (
        <div className='w-[95%] m-auto pt-[1rem] grid grid-flow-row text-black-100 dark:text-white-500 gap-4 justify-items-stretch'>
            <HomeSearchBar className={"home-search-bar mt-[1rem] flex !relative md:hidden"}/>
            <h1 className='text-2xl leading-[200%] font-bold'>CATEGORIES</h1>
            <div
                className='w-full bg-gray-100 dark:bg-black-400 p-2 rounded-2xl grid gap-2 grid-cols-2 md:grid-cols-4 lg:grid-cols-6'>
                {categories.map(categ => <CategoryIcon category={categ} key={categ._id}/>)}
            </div>
        </div>
    )
}

export default Categories