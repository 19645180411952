import React, {useMemo} from "react";
import useRateAndReview from "../hooks/useRateAndReview";
import RatingBar from "./RatingBar";


export default function SellerRatingStats({sellerId, field}: { sellerId: string, field: string }) {
    const { calculateRating, reviews } = useRateAndReview();
    const rating = useMemo(
        () => calculateRating(sellerId)
        , [reviews, calculateRating, sellerId]);
    let title = field.toLowerCase().includes("product") ? "Product Rating" : "Service Rating"

    const getAverage = () => {
        let average = Object.keys(rating[field]).reduce((acc, curr)=>acc+=(rating[field][curr] * Number(curr)), 0)
        let reviewers = Object.values(rating[field]).reduce((acc, curr:any)=>acc+curr, 0)
        if(typeof reviewers === 'number')return (average/reviewers).toFixed(1)
        return 0
    }

    return <span className={"grid p-2 flex-1 min-w-[250px] h-max gap-[0.5rem] auto-rows-max"}>
        <p className={"text-2xl"}>{title}<i>({getAverage()})</i></p>
        {rating[field] && Object.keys(rating[field]).reverse().map((key, i) => <RatingBar total={
            rating.reviews.length
            // @ts-ignore
        } raters={rating[field][key]} star={key} key={i}/>)}
    </span>
}

