import {Button, Card, TextareaField} from "evergreen-ui";
import useNotification from "../hooks/useNotification";
import useRateAndReview from "../hooks/useRateAndReview";
import React, {useState} from "react";
import productStore from "../store/productStore";


export default function ReportSeller(){
    const { showNotification, showError } = useNotification();
    const { viewProduct } = productStore()
    const { reportSeller } = useRateAndReview();
    const [complaint, setComplaint] = useState("")
    const [spin, setSpin] = useState(false)
    const submit = async () => {
        let sellerId = viewProduct?.seller.sellerId;
        if(!sellerId) return showNotification("Error!", "No seller details, please refresh and try again!")
        setSpin(true)
        await reportSeller(sellerId, complaint)
            .then(message => showNotification("Success!", message))
            .catch(err => showError(err))
            .finally(()=>setSpin(false))
    }

    return <div className={"w-full grid top-0 flex-1 h-max p-2 bg-white-500 dark:bg-black-400 rounded-md "}>
        <TextareaField value={complaint} onChange={(e:any)=>setComplaint(e.target.value)} label={"Write complaint"} />
        <Button onClick={submit} className={"primary-btn-red"}>Report</Button>
    </div>
}

