import Page from "../components/Page";
import useSearch from "../hooks/useSearch";
import BubbleSpinner from "../components/BubbleSpinner";
import ShowProduct from "../components/ShowProduct";
import SearchPanel from "../MajorComponents/SearchPanel";
import {useState} from "react";
import {ProductObj} from "../Types/Types";


export default function SearchResultsPage(){
    const { results, onlyTermSearch, editTerm } = useSearch()
    const [filtered, setFiltered] = useState<ProductObj[]|string[]>([])

    const getFiltered = () => {
        // @ts-ignore
        if(!filtered.find((p:any) => typeof p === 'string')) return filtered.map((f, i) => typeof f !== "string" ? <ShowProduct key={f._id} product={f} index={i} />:<></>)
        else return <h1 className={"m-auto absolute translate-y-1/2 w-full h-max text-center left-0 right-0 text-sky-500 text-3xl font-black "}>
            😢No item found
        </h1>
    }


    return <Page>
        <div className={"w-full min-h-[calc(100vh-3em)] m-auto relative flex "}>
            <SearchPanel currList={results} category={""} sendFilters={setFiltered} />
            <div className={"right-section !p-2 grid grid-cols-2 md:grid-cols-4 gap-[0.5rem] relative"}>
                {results.length < 1 && <BubbleSpinner/>}
                {filtered.length < 1 && results.map((product, i) => <ShowProduct index={i} product={product} key={product._id} />)}
                {getFiltered()}
            </div>
        </div>
    </Page>
}

