import {Card} from "evergreen-ui";
import React, {useState} from "react";
import ReportSeller from "./ReportSeller";
import WriteReviewAndRate from "../components/WriteReviewAndRate";


export default function RateAndReport(){
    const [tab1, setTab] = useState(true)

    return <Card elevation={2} className={"rate-n-report-tab pl-2 pr-2 grid !rounded-[13px] m-auto w-full"}>
        <span className={"w-full flex gap-1"}>
            <button onClick={()=>setTab(true)} className={`${tab1 ? "tab-btn-active":"tab-btn-inactive"} tab-btn`}>Rate Seller</button>
            <button onClick={()=>setTab(false)} className={`${tab1 ? "tab-btn-inactive":"tab-btn-active"} tab-btn`}>Report Seller</button>
        </span>
        <div className={"w-full"}>
            {
                tab1 ?
                <WriteReviewAndRate />
                :
                <ReportSeller />
            }
        </div>
    </Card>
}

