import { Heading } from 'evergreen-ui'
import React from 'react'
import { contacts } from '../utils/data'
import Atag from '../components/Atag'
import { useLocation } from 'react-router-dom'

const Footer = () => {
    const { pathname } = useLocation()
    const Group = ({children, h1}:{children: any, h1:string}) => {
    return(<span className='w-full text-white text-left m-auto grid grid-flow-row gap-[0.5rem] '>
        <Heading size={600} color="" className='text-red underline underline-offset-4' >{h1}</Heading>
        {children}
        </span>
    )
    }
    
    if(!/cart|sell|dashboard|auth/.test(pathname))return (
    <footer className=' w-full text-white-500 grid grid-flow-row min-h-[30vh] bg-black-500'>
        <div className=' w-[90%] m-auto grid grid-cols-2'>
            <Group h1="Reach Us">
                {contacts.map((cont, i) => <Atag link={cont.link} text={cont.text} icon={<cont.icon />}  key={i} />)}
            </Group>
            
        </div>
        <p className='w-[90%] text-center m-auto float-right'>&copy;{new Date(Date.now()).getFullYear()} JARA.CO.KE</p>
    </footer>
  )
  return <></>
}

export default Footer