
export const colors = {
    primary: {
        50: "#fdd3da",
        100: "#fa91a3",
        200: "#f7647e",
        300: "#f64e6c",
        400: "#f53859",
        500: "#f42247"
    },
    white: {
        50: "#8f9baa",
        100: "#a5afbb",
        200: "#bcc3cc",
        300: "#d2d7dd",
        400: "#e9ebee",
        500: "#ffffff"
    },
      black: {
        50: "#192b44",
        100: "#16263b",
        200: "#132033",
        300: "#101b2b",
        400: "#0c1622",
        500: "#091019"
      }
}