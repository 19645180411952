import useRateAndReview from "../hooks/useRateAndReview";
import {useMemo} from "react";
import ShowReview from "../components/ShowReview";


export default function ReviewsSection({sellerId, productId}:{sellerId: string, productId: string}){
    const { reviews } = useRateAndReview()
    const sellersReview = useMemo(()=>{
        return reviews.flatMap(r => {
            if(r.sellerId === sellerId) return r.reviews.filter(p =>p.productId === productId);
            return []
        }).filter(r=>r.review); // TODO: fix comments
    }, [productId, reviews, sellerId])

    return <section className={"w-[95%] min-h-[40vh] m-auto"}>
        <span className={"font-bold text-2xl"}>Comments</span>
        <div className={"w-full h-full grid  gap-[0.5rem] rounded-lg bg-white-400 dark:bg-black-400 p-2"}>
            {sellersReview.length < 1 && <span>No comments yet....</span>}
            {sellersReview.map((rev, i)=><ShowReview review={rev} key={i} />)}
        </div>
    </section>
}

