import Page from "../components/Page";
import {useLocation, useParams} from "react-router-dom";
import productStore from "../store/productStore";
import {Button, Card, Spinner} from "evergreen-ui";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {comma, pickProperty} from "../utils/functions";
import useNotification from "../hooks/useNotification";
import ProductImages from "../MajorComponents/ProductImages";
import ContactSeller from "../MajorComponents/ContactSeller";
import SubmitYourOfferForm from "../components/SubmitYourOfferForm";
import {AiOutlineArrowDown, AiOutlineArrowUp} from "react-icons/ai";
import useRateAndReview from "../hooks/useRateAndReview";
import ReportSeller from "../MajorComponents/ReportSeller";
import RateAndReport from "../MajorComponents/RateAndReport";
import {units} from "../utils/data";
import ReviewsSection from "../MajorComponents/ReviewsSection";
import {Property} from "../Types/Types";

const value = (p:Property) => {
    if(Number.isNaN(p.value) || p.property.toLowerCase() === "year of manufacture" || p.property === "Y.O.M") {
        return p.value;
    }
    else return comma(p.value)
}

const property = (p:string) => {
    if(p.toLowerCase() === "year of manufacture") return "Y.O.M"
    return p.toUpperCase()
}


export default function SeeProductPage(){
    const params = useParams();
    const { showError } = useNotification();
    const { getSellerReviews } = useRateAndReview();
    const { viewProduct, products, setViewProduct, getOneProduct  } = productStore();
    const [ showOfferWidget, setShowOfferWidget ] = useState(false)
    
    
    const setProduct = useCallback(async () => {
        if (!viewProduct) {
            const productFound = params.product_id && products[params.product_id];
            if (productFound) {
                const productList = products[params.category ?? ''];
                const product = productList && productList.find(prod => prod._id === params.product_id);
                if (product) return setViewProduct(product)
            }
            try {
                await getOneProduct(params.product_id as string);
            } catch (err) {
                showError(err);
            }
        }
    }, [getOneProduct, params.category, params.product_id, products, setViewProduct, showError, viewProduct]);


    useEffect(() => {
        Promise.resolve(setProduct())
            .catch(err=>showError(err))
        Promise.resolve(getSellerReviews(viewProduct?.seller.sellerId as string))
            .catch(err => showError(err))
    }, [getSellerReviews, params.category, setProduct, showError, viewProduct?.seller.sellerId]);

    if(!viewProduct) return <Page>
        <div className={"w-full h-[calc(100vh-3.5em)] flex justify-center"}>
            <Spinner className={"m-auto"} />
        </div>
    </Page>

    return <Page>
        <div className={"w-full h-max min-h-[calc(100vh-3.5em)] pb-[1rem] top-[0.5em] flex flex-col md:flex-row lg:flex-row  "}>
            <div className={'w-full flex-0 basis-[40%] h-max grid grid-flow-row md:max-h-max lg:max-h-max '}>
                <ProductImages images={viewProduct.images} />
                <ShowDescription showInPc={true} description={pickProperty(viewProduct.properties, "description")} />
            </div>
            <div className={"w-full h-max flex-1  grid grid-flow-row gap-[1rem] p-2"}>
                {/*TODO: make condition be a must */}
                <Card elevation={1} className={"w-full p-2 grid grid-flow-row gap-2"}>
                    <p className={"text-2xl font-bold"}>Kshs. {comma(viewProduct.price)}</p>
                    <span className={"h-[5px]"} />
                    <h1 className={"font-bold text-[1.5rem]"}>PRODUCT DETAILS</h1>
                    <table className={"see-product-table"} cellSpacing={0} cellPadding={0} border={0} >
                        <thead className={''}>
                            <tr className={''}>
                                {viewProduct.properties.filter(p=>p.property!=='Description').map((h,i)=><th className={"w-[40%]"} key={i}>{property(h.property)}</th>)}
                                <th>LOCATION</th>
                            </tr>
                        </thead>
                        <tbody className={"w-full "}>
                            <tr className={""}>
                                {viewProduct.properties.filter(p=>p.property!=='Description').map((h, i)=>{
                                    return <td key={i}>{value(h)?value(h):<i className={"text-white-200"}>unspecified</i>} {units[h.property.toLowerCase()]}</td>
                                })}
                                <td>
                                    {viewProduct.location.county + ", "+viewProduct.location.ward}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ShowDescription showInPc={false} description={pickProperty(viewProduct.properties, "description")}  />
                </Card>
                {showOfferWidget && <SubmitYourOfferForm hideWidg={()=>setShowOfferWidget(false)} />}
                {/*TODO: change store to appropriate name*/}
                <ContactSeller product={viewProduct} showOfferWidg={()=>setShowOfferWidget(true)} />
                <RateAndReport />
            </div>
        </div>
        <ReviewsSection sellerId={viewProduct.seller.sellerId} productId={viewProduct._id} />
    </Page>
}

const ShowDescription = ({description, showInPc}:{showInPc: boolean, description: {property:string, value:string}}) => {
    const [readAll, setReadAll] = useState(false)
    const getStyle = () => showInPc ? "p-5 hidden md:block lg:block" : "block md:hidden lg:hidden"

    return <span className={getStyle()+"dark:!shadow-none"}>
        <h1 className={"text-[1.5rem]"}>Description</h1>
        <p id={"description"} className={`transition-all ${readAll?"line-clamp-0":"line-clamp-2 md:line-clamp-4 "}`}>
            {description.value}
        </p>
        <Button onClick={()=>setReadAll(!readAll)} iconAfter={readAll ? AiOutlineArrowUp : AiOutlineArrowDown} className={"primary-btn active:shadow-none"}>
            Read{readAll ? " less" : " more"}
        </Button>
    </span>
}