import { AxiosError } from "axios";
import { create } from "zustand";
import {notificObject} from "../Types/Types";


export interface useNotificationModel {
    show: boolean;
    notification: notificObject;
    showNotification: (title:"Success!" | "Error!" | "Warning!", message: string)=>void;
    showError: (error: Error | AxiosError | any ) => void;
}
const defaultNotification: notificObject = {title: "Warning!", message: "hello world!"};

const useNotification = create<useNotificationModel>((set, get) =>({
    notification: defaultNotification,
    show: false,
    
    showNotification: (title, message)=>{
        set(state => ({...state, notification: {title: title, message: message}, show: true}));
        setTimeout(()=>{
            set(state => ({...state, show: false, notification: defaultNotification}));
        }, 5000)
    },
    showError: (error) => {
        try {
            let message = error.response.data.message
            set(state => ({...state, show: true, notification: {title: "Warning!", message: message}}))
        } catch (err) {
            if(!error.message){
                set(state => ({...state, show: true, notification: {title: "Warning!", message: error}}))
            }else set(state => ({...state, show: true, notification: {title: "Error!", message: error.message}}))
        }
        setTimeout(()=>{
            set(state => ({...state, show: false, notification: defaultNotification}));
        }, 3000)
    }
}))

export default useNotification;
