import Page from "../components/Page";
import LeftPanel from "../MajorComponents/LeftPanel";
import {dashboardPaths} from "../utils/paths";
import {Route, Routes, useNavigate} from "react-router-dom";
import OrdersSub from "../subpages/OrdersSub";
import AccountSub from '../subpages/AccountSub';
import BuyerOffersSub from "../subpages/BuyerOffersSub";
import LogoutSub from "../subpages/LogoutSub";
import useUserAccess from "../hooks/useUserAccess";
import AuthPage from "./AuthPage";
import {startTransition, useEffect} from "react";


export default function DashboardPage(){
    const navigate = useNavigate();
    const { userToken } = useUserAccess()

    useEffect(() => {
        if(!userToken()) startTransition(()=>startTransition(()=>navigate("/auth")))
    }, []);

    return <Page >
        <div className={"w-full flex flex-row flex-wrap "}>
            <LeftPanel paths={dashboardPaths} style={{minHeight: "calc(100vh - 3em)"}} />
            <section className={"right-section"}>
                <Routes>
                    {/*<Route path={"/"} element={<OrdersSub />} />*/}
                    <Route index={true} element={<BuyerOffersSub />} />
                    {/*<Route path={"/account"} element={<AccountSub />} />*/}
                    {/*<Route path={"/logout"} element={<LogoutSub />} />*/}
                </Routes>
            </section>
        </div>
    </Page>
}

