import axios from "axios";

// TODO: clear this token and rename from minisToreken to relevant name

const path = () =>
  window.location.href.includes("localhost")
    ? "http://localhost:5000" //"https://test.jara.co.ke"
    : "https://api.jara.co.ke";

const sendReq = axios.create({
  baseURL: `${path()}/api`,
});

sendReq.interceptors.request.use((config) => {
  const token = localStorage.getItem("minisToreken");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

sendReq.interceptors.response.use((response)=>response, (err)=>{
  if(axios.isCancel(err)) return Promise.reject("You cancelled the request!")
  return Promise.reject(err)
})
export default sendReq;
