import React, {startTransition, useEffect, useState} from "react";
import { Button, CrossIcon, TextInputField } from "evergreen-ui";
import useUserAccess from "../hooks/useUserAccess";
import useNotification from "../hooks/useNotification";
import { BiEdit, BiSave } from "react-icons/bi";
import { UserProfile } from "../Types/Types";
import PasswordResetForm from "../components/PasswordResetForm";
import {useNavigate} from "react-router-dom";


export default function AccountSub(){
    const { editProfile, userProfile, getUserProfile, updateUserProfile } = useUserAccess();
    const { showError, showNotification } = useNotification();
    const [ spin, setSpin ] = useState(false)
    const [edit, setEdit ] = useState(false);
    const [old, setOld] = useState<UserProfile>(userProfile);
    const { userToken } = useUserAccess();
    const navigate = useNavigate();

    const change = (e:React.ChangeEvent<HTMLInputElement>) => {
        editProfile(e.target.name, e.target.value)
    }

    const submit = async (form: React.FormEvent<HTMLFormElement>) => {
        form.preventDefault()
        setSpin(true)
        await updateUserProfile(form, old)
            .then(message=>showNotification("Success!", message))
            .catch(err=>showError(err))
            .finally(()=>setSpin(false))
    }

    useEffect(() => {
        if(userToken())getUserProfile().then(()=>setOld(userProfile)).catch(err=>{
            showError(err)
            setTimeout(()=>startTransition(()=>navigate("/auth")), 3000)
        });
    }, [getUserProfile, showError]);


    return <div className={"w-full gap-[0.5rem] grid md:grid-cols-2 "}>
        <form onSubmit={submit} className={"w-full rounded-3xl bg-white-500 dark:bg-black-100 p-[1rem] shadow-md max-w-md m-auto"}>
            <h1 className={"text-xl font-bold"}>Profile</h1>
            <div className={"grid md:grid-cols-2 lg:grid-cols-2 gap-2"}>
                <TextInputField disabled={!edit} value={userProfile.firstName} onChange={change} name={"firstName"} label={"First Name"} />
                <TextInputField disabled={!edit} value={userProfile.secondName} onChange={change} name={"secondName"} label={"Second Name"} />
            </div>
            <span className={"grid md:grid-cols-2 lg:grid-cols-2 gap-2"}>
                <TextInputField disabled={!edit} value={userProfile.userName} onChange={change} name={"userName"} label={"Username/Business Name"} />
                <TextInputField disabled={!edit} value={userProfile.email} onChange={change} name={"userName"} label={"Email"} />
            </span>
            <span className={"grid md:grid-cols-2 lg:grid-cols-2 gap-2"}>
                <TextInputField disabled={!edit} value={userProfile.phone} onChange={change} name={"phone"} label={"Phone"} type={"tel"} />
                <TextInputField disabled={!edit} value={userProfile.whatsapp} onChange={change} name={"whatsapp"} label={"Whatsapp"} type={"tel"} />
            </span>
            <TextInputField disabled={!edit} value={userProfile.idNumber} onChange={change} name={"idNumber"} label={"ID Number"} />
            <span className={"w-full gap-[0.5rem] grid md:grid-cols-2"}>
                <Button className={"primary-btn"} isLoading={spin} disabled={spin} onClick={()=>setEdit(!edit)} type={!edit?"submit":"button"} iconBefore={edit ? BiSave : BiEdit} appearance={'primary'}>
                    {edit ? "Save" : "Edit"}
                </Button>
                {edit && <Button className={"primary-btn-red"} iconBefore={CrossIcon} intent={'danger'} type={"button"} >
                    Cancel
                </Button>}
            </span>
        </form>
        <PasswordResetForm />
    </div>
}

