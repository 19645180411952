import React, {startTransition, useEffect, useState} from 'react'
import {PagePaths} from '../utils/paths'
import PcNavBtn from '../components/PcNavBtn'
import useUserAccess from '../hooks/useUserAccess'
import {useNavigate} from "react-router-dom";
import MobileDropDown from "../components/MobileDropDown";
import {IconButton, LightbulbIcon, MoonIcon} from "evergreen-ui";
import NavLogo from "../components/NavLogo";

const NavigationBar = () => {
    const {userIsLoggedIn} = useUserAccess();
    const navigate = useNavigate();
    const [theme, setTheme] = useState<'light'|'dark'>("light");

    useEffect(() => {
        let savedTheme = localStorage.getItem("jaraTheme");
        if(theme === 'dark' || savedTheme === "dark") document.documentElement.classList.add("dark")
        else document.documentElement.classList.remove("dark")
        if(savedTheme)setTheme(savedTheme as 'dark' | 'light')
    }, [theme]);
    const changeTheme = () => {
        setTheme(theme === "dark" ? "light" : "dark")
        localStorage.setItem("jaraTheme", theme === "dark" ? "light" : "dark")
    }
    return (
        <nav
            className='z-[900] sticky top-0 flex flex-row justify-between h-[3em] leading-[3em] shadow-md bg-sky-500 dark:bg-gradient-to-r from-black-50 from-80% to-blue-500 '>
            <NavLogo />
            <div
                className='ml-auto hidden pr-2 md:grid grid-flow-col transition-all duration-1000 auto-cols-max mt-auto mb-auto gap-[0.5rem]'>
                {PagePaths.map(path => {
                    if (path.name === 'SELL') return <PcNavBtn thisPath={path} key={path.name} />
                    return <PcNavBtn thisPath={path} key={path.name.toUpperCase()}/>
                })
                }
            </div>
            <div className={"ml-auto md:hidden pr-2 grid grid-flow-col justify-around gap-[0.5rem]"}>
                <button onClick={() => startTransition(()=>navigate("/sell"))}
                        className={"bg-black-50 w-[70px] font-bold h-[2.5em] mt-auto mb-auto text-white-500 rounded-md leading-[2.5em]"}>
                    SELL
                </button>
                <MobileDropDown paths={PagePaths.filter(p => p.path !== (userIsLoggedIn() ? "auth" : "profile"))} position={"right-0"}/>
            </div>
            <IconButton onClick={changeTheme} icon={theme==="light"?MoonIcon:LightbulbIcon}
                className={"!outline-none !border-transparent !bg-black-400 !w-[3em!important] !h-[3em] mt-auto mb-auto mr-1"}
            />
        </nav>
    )
}

export default NavigationBar