import React, { useState } from 'react'
import { subscriptionModel } from '../Types/Types'
import {Button, Card, MobilePhoneIcon} from 'evergreen-ui'
import {comma} from "../utils/functions";
import sendReq from "../utils/axios";
import {clickLink} from "../MajorComponents/ContactSeller";
import useNotification from "../hooks/useNotification";
import siteData from "../store/siteData";
import {FaCheck} from "react-icons/fa";
import {planIcons} from "../utils/data";

const ShowSubscription = ( {plan, index}:{plan: subscriptionModel, index: number} ) => {
    const { showError } = useNotification();
    const { userPlan, plans } = siteData()
    const [spin, setSpin ] = useState(false);

    const subscribed = plan._id  === userPlan?.planId;

    const payForSubscription = async() => {
        setSpin(true)
        await sendReq.get(`/subscribe/${plan._id}`)
            .then(({data})=>{
                clickLink(data.redirect)
            })
            .catch(err => showError(err))
            .finally(()=>setSpin(false))
    }

    return (
    <Card elevation={2} className='min-w-[300px] flex-1 md:h-[40vh] grid p-2 bg-white-500 dark:!bg-black-200 dark:shadow-none transition-all cursor-default hover:scale-[1.009] text-left '>
        <span className={"flex w-max"}>
            <h1 className='font-bold w-[104%] mt-auto mb-auto left-[-2%] p-2 text-black-50 dark:text-white-500 text-2xl'>{plan.name.toUpperCase()}</h1>
            <img className={"w-[2rem] h-[2rem] mt-auto mb-auto  object-contain"} src={planIcons[index]} alt={"icon"} />
        </span>
            {/*<p>{plan.perk}</p>*/}
        <span className='flex flex-wrap w-full'>
            <p className='font-bold m-auto min-w-[40%] text-left '>DAYS</p>
            <h2 className='min-w-[60%] m-auto '>{plan.duration === 0?"Forever":`${plan.duration} days`}</h2>
        </span>
        <span className='flex flex-wrap w-full '>
            <p className='font-bold m-auto min-w-[40%] text-left '>ADS </p>
            <h2 className='min-w-[60%] m-auto '>{plan.productNo}</h2>
        </span>
        <span className='flex flex-wrap w-full '>
            <p className='font-bold m-auto min-w-[40%] text-left '>PRICE</p>
            <h2 className='min-w-[60%] m-auto '>Kshs.{comma(plan.price)}</h2>
        </span>
        <Button  disabled={plan.price < 1 || subscribed} onClick={payForSubscription} className={"w-full m-auto primary-btn"} isLoading={spin} iconAfter={subscribed ? FaCheck : MobilePhoneIcon} >
            {subscribed ? "Active" : "Subscribe"}
        </Button>
    </Card>
  )
}

export default ShowSubscription