import {startTransition, useEffect, useState} from "react";
import useUserAccess from "../hooks/useUserAccess";
import {useNavigate} from "react-router-dom";


export default function LogoutSub(){
    const { logout } = useUserAccess();
    const navigate = useNavigate()

    useEffect(() => {
        logout().then(loggedOut => startTransition(()=>window.location.replace("/")))
    }, [logout, navigate]);

    return <></>
}

