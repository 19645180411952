import page from "../components/Page";
import {create} from "zustand";


export interface Page {
    [key:string]:string|boolean;
    pageName: string;
    loaded: boolean;
}

interface usePageIsLoadedObj {
    pages: Page[];
    setIsLoaded: (pageName: string, isLoaded: boolean) => void;
}

const usePageIsLoaded = create<usePageIsLoadedObj>((set, get)=>({
    pages: [{pageName: "home", loaded: false}, {pageName: "viewProducts", loaded: false}],

    setIsLoaded: (pageName, isLoaded)=>{
        let pages = get().pages;
        pages.forEach(p => {
            if(p.pageName === pageName) p.loaded = isLoaded;
        })
        setTimeout(()=>{
            set(prev=>({...prev, pages: pages}))
        }, 3000)
    }
}))

export default usePageIsLoaded;

