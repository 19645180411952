import {create} from "zustand";
import {CartItem, checkout, checkoutProduct, MyOrder, orderAddress, paymentMethod, ProductObj} from "../Types/Types";
import sendReq from "../utils/axios";
import {redirectToPayment} from "../utils/functions";


interface inStoreHook {
    products: ProductObj[];
    cart: CartItem[];
    order: checkout;
    card_redirect_url: string;
    myOrders: MyOrder[],

    makePurchase: ()=>Promise<string>;
    editOrder:(key:string, value:number|string|checkoutProduct[]|paymentMethod|orderAddress)=>void;
    addToCart: (product: ProductObj, quantity: number)=>void;
    delFrmCart: (productId: string) => void;
    getInStoreProducts?: ()=>void;
    isInCart: (productId:string)=>boolean;
    persistCart: ()=>boolean;
    getMyOrders: ()=>Promise<Error>;
}

const inStore = create<inStoreHook>((set, get)=>({
    products: [],
    cart: [],
    card_redirect_url: "",
    order: {totalCost: 0, products: [], instructions: "", address: {town: "", county: ""}},
    myOrders: [],


    getMyOrders: () => {
        return new Promise(async(_resolve, reject)=>{
            await sendReq.get("/buyer-orders")
                .then(({data})=>{
                    set(state=>({...state, myOrders: data}))
                })
                .catch(err => reject(err))
        })
    },
    makePurchase:()=>{
        return new Promise(async(resolve, reject)=>{
            let order = get().order;
            order.products = get().cart.map(cart=>({productId: cart.product._id, quantity: cart.quantity}))
            set(state=>({...state, order: order}))
            await sendReq.post("/purchase", order)
                .then(({data})=>{
                    // clear current cart
                    resolve(data.message)
                    // noinspection JSUnresolvedReference
                    if(data.redirect_url){ // noinspection JSUnresolvedReference
                        redirectToPayment(data.redirect_url)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    editOrder: (key, value)=>{
        let copy = get().order;
        copy[key] = value;
        // copy.totalCost = get().cart.reduce((acc, curr)=>acc+=(curr.product.price * curr.quantity),0)
        set(state=>({...state, order: copy}))
    },
    isInCart:(productId)=>{
        let index = get().cart.findIndex(p => p.product._id === productId)
        return index >= 0
    },
    addToCart: (product, quantity)=>{
        let found = get().cart.findIndex(p => p.product._id === product._id);
        if(found === -1) {
            let cart = get().cart;
            cart.push({product: product, quantity: quantity, date: Date.now()})
            set(state=>({...state, cart: cart}));
            return get().persistCart();
        }
        let copy = get().cart;
        copy[found] = {...copy[found], quantity: quantity, date: Date.now()};
        set(state=>({...state, cart: copy}), get().persistCart());
        get().persistCart();
    },
    delFrmCart: (productId)=>{
        let copy = get().cart.filter(p => p.product._id !== productId);
        set(state=>({...state, cart: copy}));
        localStorage.setItem("@@localCart", JSON.stringify(get().order));
    },
    persistCart: ()=>{
        // get cart from localStorage TODO: rename with appropriate name
        let localCart = localStorage.getItem("@@localCart");
        if(!localCart) {
            localStorage.setItem("@@localCart", JSON.stringify(get().order));
            return true
        }
        let localCartObj:CartItem[] = [];
        try{
            localCartObj = JSON.parse(localCart);
        }catch(err){
            localStorage.setItem("@@localCart", JSON.stringify(get().order));
        }
        let copy = get().cart;
        let longerCart = copy.length >= localCartObj.length ? copy : localCartObj;
        let shorterCart = copy.length <= localCartObj.length ? copy : localCartObj;
        let finalArr: CartItem[] = [];
        for(let indx = 0; indx < longerCart.length;indx++){
            if(shorterCart[indx]){
                if(shorterCart[indx].date > longerCart[indx].date) finalArr.push(shorterCart[indx])
                else finalArr.push(longerCart[indx]);
            }else finalArr.push(longerCart[indx])
        }
        localStorage.setItem("@@localCart", JSON.stringify(finalArr))
        set(state=> ({...state, cart: finalArr}))
        return true;
    },
}))

export default inStore;