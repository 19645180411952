import { useEffect } from "react";
import BubbleSpinner from "../components/BubbleSpinner";
import logo from "../assets/images/logoTrans.webp"

export default function SplashPage({show}: { show: boolean }) {

    useEffect(() => {
        if (show) {
            document.body.style.height = "100vh";
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.height = "max-content";
            document.body.style.overflow = "auto";
        }
        return (() => {
            document.body.style.height = "max-content";
            document.body.style.overflow = "auto";
            window.scrollTo({top: 0, behavior: "smooth"})

        })
    }, [show]);

    if (!show) return <></>
    return <div className={"w-full h-[100vh] fixed top-0 z-[1000] flex text-white-500 bg-black-50"}>
        <div className={"w-max h-max m-auto text-center grid grid-flow-row"}>
            <img data-aos={""} className={"w-full m-auto h-full object-contain select-none "} src={logo} alt={"logo"} />
            <div>
                <BubbleSpinner />
            </div>
        </div>
    </div>
}

