import {Button, Card, PhoneIcon} from "evergreen-ui";
import {ProductObj} from "../Types/Types";
import {WhatsappIcon} from "react-share";
import {MdEmail} from "react-icons/md";
import React, {useCallback, useMemo} from "react";
import {useLocation} from "react-router-dom";
import {BiCartAdd} from "react-icons/bi";
import {BsCreditCard} from "react-icons/bs";
import inStore from "../store/InStore";
import useRateAndReview from "../hooks/useRateAndReview";
import StarBtn from "../components/StarBtn";
import {fixPhone} from "../utils/functions";

export const clickLink = (href: string) => {
    let a = document.createElement('a');
    a.href = href;
    a.target = "_blank";
    document.lastChild?.appendChild(a);
    a.click();
    document.lastChild?.removeChild(a);
}

export default function ContactSeller({product, showOfferWidg}: { product: ProductObj, showOfferWidg: () => void }) {
    const {addToCart, delFrmCart, isInCart} = inStore();
    const {reviews, calculateRating,} = useRateAndReview();
    const {pathname} = useLocation();
    
    const rating = useMemo(()=>calculateRating(product.seller.sellerId), 
        [reviews, calculateRating, product.seller.sellerId])
    
    const getAverage = useCallback((field: string) => {
        let average = Object.keys(rating[field]).reduce((acc, curr)=>acc+=(rating[field][curr] * Number(curr)), 0)
        let reviewers = Object.values(rating[field]).reduce((acc, curr:any)=>acc+curr, 0)
        if(!Number.isNaN(reviewers) && typeof reviewers === "number")return Number((average/reviewers).toFixed(1))
        return 0
    }, [rating])

    const getRating = useMemo(()=>{
        let service = getAverage('serviceRating');
        let products = getAverage("productRating");
        return (service+products)/2
    }, [getAverage])


    return <Card elevation={2} className={"p-2 w-full !rounded-[13px] h-max bg-white-500 dark:bg-black-400 "}>
        <div className={"w-full grid gap-[0.5rem]"}>
            <h1 className={"font-bold text-[1.5rem]"}>SELLER DETAILS</h1>
            <span className={"gap-2 min-h-[3em] grid leading-[3em]"}>
                <span className={"flex gap-2 h-max leading-normal"}>
                    <h2 className={"font-bold text-[1.2rem]"}>NAME</h2>:
                    <h1 className={"mt-auto mb-auto text-[1.2rem]"}>{product.seller.name}</h1>
                </span>
                <span className={"flex float-left"}>
                    {[1, 2, 3, 4, 5].map((n, i)=><StarBtn key={i} star={n} count={getRating} />)}
                    <i>({getRating})</i>
                </span>
            </span>
            <div className={"w-full grid grid-cols-3 gap-[0.5rem]"}>
                <Button onClick={() => clickLink(`tel:${product.seller.phone}`)}
                        className={"w-full !rounded-[13px] !text-white-500 !bg-blue-500"} iconBefore={PhoneIcon}>
                    Phone
                </Button>
                <Button onClick={() => clickLink(`https://wa.me/${fixPhone(product.seller.whatsapp)}`)}
                        className={"w-full !rounded-[13px] !text-white-500 !bg-[#25D366]"} iconBefore={WhatsappIcon}>
                    Whatsapp
                </Button>
                <Button onClick={() => clickLink(`mailto:${product.seller.email}`)}
                        className={"w-full !rounded-[13px] !text-white-500 !bg-black-50"} iconBefore={MdEmail}>
                    Email
                </Button>
            </div>
            {!pathname.includes("jara-store") ?
                <Button onClick={showOfferWidg} appearance={"primary"} className={"primary-btn mb-auto mt-auto"}>
                    Submit your offer
                </Button> :
                <div className={"w-full flex gap-2"}>
                    <Button iconBefore={<BsCreditCard/>} appearance={"primary"}>Buy Now</Button>
                    <Button
                        onClick={isInCart(product._id) ? () => delFrmCart(product._id) : () => addToCart(product, 1)}
                        iconBefore={<BiCartAdd/>} appearance={"primary"}>
                        {isInCart(product._id) ? "Remove from cart" : "Add to cart"}
                    </Button>
                </div>
            }
        </div>
    </Card>
}