import {getRandomId} from './functions';
import {MdEmail} from 'react-icons/md'
import {BsFacebook, BsInstagram, BsLinkedin, BsTiktok, BsTwitter, BsYoutube} from 'react-icons/bs'
import {PhoneIcon} from 'evergreen-ui';
import {categoryObj, contact, PropToShow, UnitObj} from '../Types/Types';
import {LinkedinIcon, TwitterIcon} from "react-share";
import {SiBoost} from "react-icons/si";
import {FaTwitter} from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";
// import { categoryObj } from '../store/siteData';


export const siteCategories: categoryObj[] = [
    {
        _id: getRandomId(), category: "Cars/Vehicle", subCategories: [
            "Cars", "Trucks", "Trailers", "Motorcycles", "Buses/Vans", "Containers", "Heavy Equipment"
        ], image: require("../assets/images/cars.webp"), properties: [
            {property: "Brand", type: "text", req: true},
            {property: "Model", type: "text", req: true},
            {property: "Mileage", type: "number", req: true},
            {property: "Engine Size", type: "number", req: true},
            {property: "Year of manufacture", type: "number", req: true},
            {property: "Color", type: "text", req: true},
            {
                property: "Wheel drive", type: "select", req: true, options: [
                    "All-wheel-drive(AWD)", "Rear-wheel-drive(RWD)", "Four-wheel-drive(4WD)"
                ]
            },
            {
                property: "Body Type", type: "select", req: true, options: [
                    "Saloon", "Sedan", "Station wagon", "SUV", "Hatchback", "Coupe", "Minivan", "Pickup truck", "Convertible",
                    "Muscle car", "Sports car", "Van"
                ]
            },
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Real Estate", subCategories: [
            "House to Let", "House of sale", "Apartment to let", "Apartments for sale", "Land to let", "Land for sale",
            "Commercial property to let", "Commercial property for sale"
        ], image: require('../assets/images/houses.webp'), properties: [
            {
                property: "Property Type",
                type: "select",
                req: true,
                options: ["House to Let", "House of sale", "Apartment to let", "Apartments for sale", "Land to let", "Land for sale",
                    "Commercial property to let", "Commercial property for sale"]
            },
            // {property: "Location", type: "text", req: true}, // TODO: Fix location
            {property: "Size", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: true},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Electronics", subCategories: [
            "Accessories", "Laptop & Computers", "TV", "Music Equipment", "Cameras", "Software", "Printers",
            "CCTV/Surveillance", "Headphones"
        ], image: require('../assets/images/electronics.webp'), properties: [
            {property: "Brand", type: "text", req: true},
            {property: "Model", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Mobile Phones", subCategories: [
            "Mobile Phones", "Tablets"
        ], image: require('../assets/images/phone.webp'), properties: [
            {property: "Brand", type: "text", req: true},
            {property: "Model", type: "text", req: true},
            {property: "RAM", type: "number", req: true},
            {property: "Storage", type: "number", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Home & Furniture", subCategories: [
            "Home Accessories", "Home Appliances", "Kitchen Appliances", "Furniture", "Garden Appliances", "Dining Appliances"
        ], image: require("../assets/images/furniture.webp"), properties: [
            {property: "Product", type: "text", req: true},
            {property: "Brand", type: "text", req: false},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Fashion", subCategories: [
            "Shoes", "Clothing", "Watches", "Bags", "Jewellery", "Sportswear"
        ], image: require('../assets/images/fashion.webp'), properties: [
            {property: "Product", type: "text", req: true},
            {property: "Brand", type: "text", req: true},
            {property: "Color", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Lights & Lighting", subCategories: [
            "Smart Lighting", "Landscape Lighting", "Bathroom & Kitchen", "Coiling Lighting",
            "Indoor Lighting", "Solar Lighting", "Holiday Lighting", "Healthcare Lighting"
        ], image: require('../assets/images/lights.webp'), properties: [
            {property: "Brand", type: "text", req: false},
            {property: "Color", type: "text", req: true},
            {property: "Lamp power", type: "text", req: false},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Construction", subCategories: [
            "Building Materials", "Building Machinery", "Electrical tools", "Energy Equipment",
            "Engineering & Construction Machinery", "Used Machinery", "Plumbing"
        ], image: require('../assets/images/construction.webp'), properties: [
            {property: "Product", type: 'text', req: true},
            {property: "Brand", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {type: "number", property: "Price", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Agriculture", subCategories: [
            "Animal Products", "Animal Feed", "Agricultural Equipment", "Timber Materials",
            "Home/Ornamental Plants", "Dairy Products", "Honey & Honey Products", "Fresh Fruits"
        ], image: require('../assets/images/agriculture.webp'), properties: [
            {property: "Product", type: "text", req: true},
            {property: "Brand", type: "text", req: false},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Toys", subCategories: [
            "Kid's accessories", "Puzzles & games", "Baby furniture", "Electronic toys", "Toy vehicles", "Toy guns",
            "Toddler toys"
        ], image: require('../assets/images/kids.webp'), properties: [
            {property: "Brand", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Services", subCategories: [
            "Professional Services", "Healthcare Services", "Retail Services",
            "Transportation Services", "Communication Services", "Utilities",
            "Financial Services", "Government Services", "Nonprofit Services",
            "Personal Services", "Environmental Services", "Security Services",
            "Consulting Services", "Creative Services", "Maintenance Services",
            "Food Services", "Education and Training Services"
        ], image: require("../assets/images/service.webp"), properties: [
            {property: "Service", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true}
        ]
    },
    {
        _id: getRandomId(), category: "Spare Parts", subCategories: [
            "Trucks", "Sedans", "Motorbikes"
        ], image: require("../assets/images/Spares.webp"), properties: [
            {property: "Spare Part", type: "text", req: true},
            {property: "Brand", type: "text", req: true},
            {property: "Model", type: "text", req: true},
            {property: "Part number", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: false},
            {property: "Price", type: "number", req: true},
        ]
    },
    {
        _id: getRandomId(), category: "Beauty/Cosmetics", subCategories: [
            "bath supplies", "body art", "body care",
            "hair care & styling", "hair extensions/wigs", "hands & feet care",
            "make up tools", "perfume & fragrance", "salon accessories",
            "skin care", "eye lashes"
        ], image: require("../assets/images/beauty.webp"), properties: [
            {property: "Product Name", type: "text", req: true},
            {property: "Brand", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Price", type: "number", req: true},
        ]
    },
    {
        _id: getRandomId(), category: "Gym & Sports", subCategories: [
            "Gym products", "Gym wear", "Gym maintenance", "Gym flooring", "Gym setup", "Sports gear",
        ], image: require("../assets/images/gym.webp"), properties: [
            {property: "Product Name", type: "text", req: true},
            {property: "Brand", type: "text", req: true},
            {property: "Condition", type: "select", req: true, options: ["Used", "Brand New"]},
            {property: "Description", type: "textarea", req: true},
            {property: "Price", type: "number", req: true},
        ]
    },
];

export const contacts: contact[] = [
    {icon: PhoneIcon, text: "Phone", link: "tel:+254712345678"},
    {icon: MdEmail, text: "Email", link: ""},
    {icon: BsFacebook, text: "Facebook", link: "https://www.facebook.com/jara.co.ke?mibextid=LQQJ4d"},
    {icon: BsInstagram, text: "Instagram", link: "https://instagram.com/jara_online_marketing?utm_source=qr"},
    {icon: BsLinkedin, text: "LinkedIn", link: ""},
    {icon: BsYoutube, text: "Youtube", link: ""},
    {icon: FaXTwitter, text: "X(Twitter)", link: ""},
    {icon: BsTiktok, text: "TikTok", link: ""},
]

export const units: UnitObj = {
    mileage: "kms",
    "engine size": "cc",
    ram: "GB",
    storage: "GB",
}

export const propsToShow: PropToShow[] = [
    {
        category: "Cars/Vehicle", properties: [
            {prop: "Brand", unit: ""},
            {prop: "Model", unit: ""},
            {prop: "Year of manufacture", unit: ""}
        ]
    },
    {
        category: "Real Estate", properties: [
            {prop: "Type", unit: ""},
            {prop: "Location", unit: ""}
        ]
    },
    {
        category: "Electronics", properties: [
            {prop: "Brand", unit: ""},
            {prop: "Model", unit: ""},
            {prop: "subCategory", unit: ""}
        ]
    },
    {
        category: "Mobile Phones", properties: [
            {prop: "Brand", unit: ""},
            {prop: "Model", unit: ""},
            {prop: "RAM", unit: "GB"},
            {prop: "Storage", unit: "GB"},
        ]
    },
    {
        category: "Home & Furniture", properties: [
            {prop: "Product", unit: ""},
            {prop: "subCategory", unit: ""},
        ]
    },
    {
        category: "Fashion", properties: [
            {prop: "Product", unit: ""},
            {prop: "subCategory", unit: ""}
        ]
    },
    {
        category: "Construction", properties: [
            {prop: "Product", unit: ""},
            {prop: "subCategory", unit: ""},
        ]
    },
    {
        category: "Lights & Lighting", properties: [
            {prop: "Brand", unit: ""},
            {prop: "Color", unit: ""},
        ]
    },
    {
        category: "Agriculture", properties: [
            {prop: "Brand", unit: ""},
        ]
    },
    {
        category: "Toys", properties: [
            {prop: "subCategory", unit: ""},
        ]
    },
    {
        category: "Services", properties: [
            {prop: "Service", unit: ""}
        ]
    },
    {
        category: "Spare Parts", properties: [
            {prop: "Spare Part", unit: ""},
            {prop: "Brand", unit: ""},
        ]
    },
    {
        category: "Gym & Sports", properties: [
            {prop: "Product Name", unit: ""},
            {prop: "subCategory", unit: ""},
        ]
    }
];

export const planIcons = [
    require("../assets/images/free.webp"),
    require("../assets/images/gold.webp"),
    require("../assets/images/goldPlus.webp"),
    require("../assets/images/plat.webp"),
    require("../assets/images/platPlus.webp"),
    require("../assets/images/diamond.webp"),
    require("../assets/images/enterprise.webp"),
    require("../assets/images/boost.png")
]
