import {filterF, ProductObj} from "../Types/Types";
import {Button, Label, ResetIcon, SearchIcon, SearchInput, Select, TextInputField} from "evergreen-ui";
import React, { useRef, useState} from "react";
import sendReq from "../utils/axios";
import {siteCategories} from "../utils/data";
import {filterFunctions, fixCategory, pickProperty} from "../utils/functions";
import useNotification from "../hooks/useNotification";
import useSiteDataStore from "../store/siteData";
import useClientStore from "../hooks/useClientStore";
import {useFilters} from "../hooks/useFilters";
import {useParams} from "react-router-dom";
import productStore from "../store/productStore";

interface props {
    currList: ProductObj[];
    category?: string;
    sendFilters: any;
}

export default function SearchPanel({currList, category, sendFilters}:props){
    const { locations } = useSiteDataStore();
    const { harmonizeResults } = useFilters();
    const { list } = useClientStore();
    const {harmonize} = productStore()
    const [f, setFilters] = useState<{[key:string]:number|string}>({
        minPrice: 0,
        maxPrice: 0,
        subCategory: "",
        location: "",
    })
    const params = useParams()
    const filtrate = async () => {
        let filters = Object.keys(f).filter(filter => f[filter])
        let products:ProductObj[] = currList;

        for(let filter of filters) {
            products = filterFunctions[filter](products, f[filter]);
            if(products.length < 50){
                let data = await harmonizeResults(products.length, params.category as string)
                let x = filterFunctions[filter](data, f[filter]).filter(x=>!products.map(p=>p._id).includes(x._id))
                products = [...new Set(products.concat(x))]
                if(x.length > 0)harmonize(x, params.category as string)
            }
        }
        if(products.length > 0) return sendFilters(products)
        sendFilters(["empty"])
    }
    const clearFilters = () => {
        sendFilters([])
        setFilters({minPrice: 0, maxPrice: 0, subCategory: "", location: ""})
    }

    const change = (e:React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        setFilters(f=>({...f, [e.target.name]:e.target.value}))
    }

    const getCategory = () => {
        if(currList.length > 0)return siteCategories.filter(c => c.category === currList[0]?.category)[0]
        return {subCategories: []}
    }
    return <section className={"w-max"}>
        <div className={"w-[250px] p-2 h-[calc(100vh-3em)] drop-shadow-lg sticky top-[3em] bg-white-500 dark:bg-black-400 hidden auto-rows-max gap-2 md:grid lg:grid "}>
            <h1 className={"text-2xl text-sky-500"}>Filters</h1>
            <TextInputField name={"minPrice"} onChange={change} value={f.minPrice} label={"Lowest Price"} type={'number'} />
            <TextInputField name={"maxPrice"} onChange={change} value={f.maxPrice} label={"Highest Price"} type={'number'} />
            <span className={'w-full grid grid-flow-row'}>
                <Label>Sub-Category</Label>
                <Select name={"subCategory"} value={f.subCategory} onChange={change} title={"Sub Category"}>
                    <option value={""}>All</option>
                    {getCategory()?.subCategories.map((sub, i)=><option value={sub} key={i}>{sub}</option>)}
                </Select>
            </span>
            <TextInputField name={"location"} list={"locations"} onChange={change} value={f.location} label={"Location"} type={'text'} />
            <datalist id={"locations"} >
                {locations.map(loc => Object.values(loc)).map(p => Object.keys(p)).map((l, i)=><option key={i}>{l}</option>)}
            </datalist>
            <span className={'grid grid-flow-col gap-2'}>
                <Button onClick={filtrate} className={"primary-btn"} iconAfter={SearchIcon}>Search</Button>
                <Button onClick={clearFilters} className={"primary-btn-red"} iconAfter={ResetIcon} >Reset</Button>
            </span>
        </div>
    {/*TODO: mobile*/}
    </section>
}