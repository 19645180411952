

interface props {
    total: number;
    raters: number;
    star: string;
}
export default function RatingBar({total, raters, star }:props){
    return <span className={"w-full gap-[0.5rem] h-max flex justify-around"} >
        <p className={" m-auto w-max leading-[3vh] h-[3vh]"}>{star} star</p>
        <div className={"flex-1 m-auto h-[5px] rounded-md overflow-hidden bg-gray-300"}>
            <div style={{width: `${(raters/total)*100}%`}} className={`w-[0%] h-full rounded-md absolute bg-primary-500`} />
        </div>
        <p className={" m-auto w-max leading-[3vh] h-[3vh]"}>{raters}</p>
    </span>
}

