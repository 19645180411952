import Page from "../components/Page";
import AccountSub from "../subpages/AccountSub";
import LeftPanel from "../MajorComponents/LeftPanel";
import { settingsPagePaths} from "../utils/paths";
import {Route, Routes, useNavigate} from "react-router-dom";
import LogoutSub from "../subpages/LogoutSub";
import useUserAccess from "../hooks/useUserAccess";
import {startTransition, useEffect} from "react";


export default function SettingsPage() {
    const { userToken } = useUserAccess();
    const navigate = useNavigate();

    useEffect(() => {
        if(!userToken()) startTransition(()=>navigate("/auth"))
    }, []);

    return <Page>
        <div className={"w-full flex flex-row flex-wrap "}>
            <LeftPanel paths={settingsPagePaths} style={{minHeight: "calc(100vh - 3em)"}}/>
            <section className={"right-section"}>
                <Routes>
                    <Route index={true} element={<AccountSub/>}/>
                    <Route path={"/logout"} element={<LogoutSub/>}/>
                </Routes>
            </section>
        </div>
    </Page>
}

