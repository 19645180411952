import Slider from "react-slick";
import React from "react";
import {Image} from "evergreen-ui";


export default function ProductImages({images}:{images: string[]}){

    let settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplaySpeed: 5000,
        centerMode: false,
        autoplay: false,
        centerPadding: "50"
    }
    return (
        <div className='product-images w-full max-w-[100vw] m-auto !h-max overflow-hidden bg-transparent '>
            <Slider adaptiveHeight={true} className={"h-full"} {...settings}>
                {images.map((url, i)=><Image  src={url} alt={"product"} key={i} />)}
            </Slider>
        </div>
    )
}
