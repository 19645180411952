import React, {startTransition, Suspense, useEffect, useRef} from 'react'
import useUserAccess from "../hooks/useUserAccess";
import {useLocation, useNavigate} from "react-router-dom";
import BubbleSpinner from "./BubbleSpinner";

interface props extends React.HTMLAttributes<HTMLDivElement> {
    children: any;
    final?: any;
}

const Page = ({children, final, ...rest}: props) => {
    const scrollCount = useRef(0)
    const navigate = useNavigate();
    const { userToken } = useUserAccess();
    const { pathname } = useLocation()

    
    useEffect(() => {
        if((pathname.includes("sell") || pathname.includes("dashboard")) && !userToken()){
            startTransition(()=>navigate("/auth"))
        }
        scrollCount.current++
        if(scrollCount.current <= 2){
            window.scrollTo({top: 0, behavior: "smooth"})
        }
    }, [navigate, pathname, userToken]);

    return (
        <Suspense fallback={<BubbleSpinner />}>
        <div {...rest} data-aos="zoom-in" className='w-full min-h-[calc(100vh-3em)] h-max bg-white-500 dark:text-white-500 dark:bg-black-300 block'>
            {final && final}
            {children}
        </div>
        </Suspense>
    )
}

export default Page