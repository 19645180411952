import productStore from "../store/productStore";
import {useLocation, useParams} from "react-router-dom";
import {useCallback, useEffect} from "react";
import useNotification from "./useNotification";
import usePageIsLoaded from "./usePageIsLoaded";


export default function useClientStore(){
    const { products, getProducts } = productStore();
    const params = useParams();
    const { showError } = useNotification();
    const { pathname } = useLocation();
    const { setIsLoaded } = usePageIsLoaded();

    const getQuery = useCallback(() => {
        if (pathname.includes("jara-store")) return "__jara"; // TODO: change to store name
        else return "_" + params.category;
    }, [params.category, pathname]);

    useEffect(() => {
        // check if the category has been fetched and added to products array in store
        let truth1 = products[params.category as string] === undefined;
        // TODO: maybe make it to check length < 50 when products are many enough
        let truth2 = truth1 ? true : products[params.category as string].length < 1;
        // if(truth1 || truth2) getProducts(getQuery())
        try{
            if(truth1 || truth2) getProducts("_"+params.category as string)
                .catch(err => showError(err))
        }catch (e) {
        }
    }, [getProducts, getQuery, params, products, setIsLoaded, showError]);


    // let inStoreProduct = () => {
    //     const allProducts = Object.values(products);
    //     return allProducts.flatMap(curr => curr).filter(p => p.seller.name?.toLowerCase() === "rimzone");
    // };
    return {
        // list: params.category ? products[params.category] : inStoreProduct(),
        list: params.category ? products[params.category] : []
    }
}

