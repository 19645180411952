/**
 *This hook has methods to handle seller store functions like:
 * 1. Fetch offers
 * */
import {create} from "zustand";
import sendReq from "../utils/axios";
import {OfferHistory, OfferObj, ProductObj} from "../Types/Types";

interface useOffersObj {
    offersAsSeller: OfferObj[];
    offersAsBuyer: OfferObj[],

    getOffersAsSeller: () => Promise<void>;
    getOffersAsBuyer: () => Promise<Error>;
    postInitialOffer: (product:ProductObj, userOffer:number)=>Promise<string>;
    counterOffer: (offer: OfferHistory, offerId: string, index: number) => Promise<string>;
    acceptOffer: (offerId: string) => Promise<string>;
    rejectOffer: (offerId: string) => Promise<string>;
}

const useOffers = create<useOffersObj>((set, get)=>({
    offersAsSeller: [],
    offersAsBuyer: [],

    rejectOffer: (offerId)=>{
        return new Promise(async(resolve, reject)=>{
            await sendReq.post("/reject-offer", { offerId: offerId })
                .then(({data})=>{
                    resolve(data.message)
                    window.location.href.includes("sell")?get().getOffersAsSeller() : get().getOffersAsBuyer()
                })
                .catch(err=>reject(err))
        })
    },
    acceptOffer: (offerId) => {
        return new Promise(async(resolve, reject)=>{
            await sendReq.post("/accept-offer", { offerId: offerId})
                .then(({data})=>{
                    resolve(data.message)
                    window.location.href.includes("sell")?get().getOffersAsSeller() : get().getOffersAsBuyer()
                })
                .catch(err=>reject(err))
        })
    },
    counterOffer: (offer, offerId, index)=>{
        return new Promise(async(resolve, reject)=>{
            let offerReq = { offerId: offerId, offer: offer, index: index }
            await sendReq.post("/counter-offer", offerReq)
                .then(({data})=>{
                    resolve(data.message)
                    window.location.href.includes("sell")?get().getOffersAsSeller() : get().getOffersAsBuyer()
                })
                .catch(err=>reject(err))
        })
    },
    getOffersAsSeller: ()=>{
        return new Promise(async(resolve,reject)=>{
            await sendReq.get("/store-offers")
                .then(({data})=>{
                    set(state=>({...state, offersAsSeller: data}));
                })
                .catch(err => {
                    reject(err);
                })
        })
    },
    getOffersAsBuyer: ()=>{
        return new Promise(async(resolve,reject)=>{
            await sendReq.get("/my-offers")
                .then(({data})=>{
                    set(state=>({...state, offersAsBuyer: data}))
                })
                .catch(err=>reject(err))
        })
    },
    postInitialOffer: (product, offer)=>{
        return new Promise(async(resolve, reject)=>{
            let offerReq = { offer: offer, product: product }
            await sendReq.post('/initial-offer', offerReq)
                .then(({data})=>resolve(data.message))
                .catch(err=>reject(err))
        })
    }
}))

export default useOffers;
