

export default function BubbleSpinner(){

    return <span className={"absolute translate-y-1/2 left-0 top-0 bottom-0 right-0 w-max h-max grid grid-cols-4 m-auto bubble-spinner"}>
            <div className={'bubble-1'} />
            <div className={'bubble-2'} />
            <div className={'bubble-3'} />
            <div className={'bubble-4'} />
        </span>
}

