import useOffers from "../hooks/useOffers";
import {useEffect} from "react";
import useNotification from "../hooks/useNotification";
import ShowOfferToBuyer from "../components/ShowOfferToBuyer";
import useUserAccess from "../hooks/useUserAccess";


export default function BuyerOffersSub(){
    const { offersAsBuyer, getOffersAsBuyer } = useOffers();
    const { showNotification, showError } = useNotification();
    const { userToken } = useUserAccess();


    useEffect(() => {
        if(userToken())getOffersAsBuyer().catch(err=>showError(err));
    }, [getOffersAsBuyer, showError]);

    return <div className={"w-full h-full"}>
        <h1>Your Offers</h1>
        <div className={"w-full grid md:grid-cols-4"}>
            {offersAsBuyer.map(offer => <ShowOfferToBuyer key={offer._id} offer={offer} />)}
        </div>
    </div>
}

