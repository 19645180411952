import React, {startTransition} from 'react'
import { path } from '../Types/Types'
import { Button } from 'evergreen-ui'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors } from '../utils/colors'

const LeftPanelButton = ({path}:{path: path}) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const switchTo = () => startTransition(()=>navigate(`./${path.path}`))

    let color = () => pathname.split("/sell").join("") === `/${path.path}` ? colors.primary['500'] : colors.black["50"]
  return (
    <Button color={color()} onClick={switchTo} border="transparent" borderRadius="0" backgroundColor={""} className='bg-black-50 !rounded-md !text-white-500 dark:bg-black-200 hover:!bg-black-100 dark:hover:!bg-black-100 dark:text-white-500 shadow-sm w-[95%] !h-[3em] m-auto' iconBefore={path.icon??""} >
        {path.name}
    </Button>
  )
}

export default LeftPanelButton