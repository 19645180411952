import {create} from "zustand";
import sendReq from "../utils/axios";


interface useInsightObj {

    addImpression: (productId: string) => Promise<void>;
    addClick: (productId: string) => Promise<void>;
}

const useInsight = create<useInsightObj>((set, get)=>({

    addImpression: (productId)=>{
        return new Promise(async(_resolve, _reject)=>{
            await sendReq.put("/add-impression", { productId: productId })
                .catch(_err => {})
        })
    },
    addClick: (productId) => {
        return new Promise(async(_resolve, _reject)=>{
            await sendReq.put("/add-click", {productId: productId})
                .catch(_err=>{})
        })
    }

}));


export default useInsight;
