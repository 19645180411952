import React, {lazy, Suspense, useEffect} from 'react';
import './App.css';
import NavigationBar from './MajorComponents/NavigationBar';
import {Route, Routes, useLocation} from 'react-router-dom';
import Footer from './MajorComponents/Footer';
import 'aos/dist/aos.css';
// @ts-ignore
import aos from 'aos';
import SeeProductPage from "./pages/SeeProductPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DashboardPage from "./pages/DashboardPage";
import usePageIsLoaded from "./hooks/usePageIsLoaded";
import SplashPage from "./pages/SplashPage";
import SubscriptionsPage from "./pages/SubscriptionsPage";
import SearchResultsPage from "./pages/SearchResultsPage";
import SettingsPage from "./pages/SettingsPage";
import HomePage from './pages/HomePage';
import BubbleSpinner from "./components/BubbleSpinner";
const Four04Page: React.FC = lazy(()=>import("./pages/Four04Page"))
const AuthPage: React.FC = lazy(()=>import("./pages/AuthPage"))
const SellPage: React.FC = lazy(()=>import("./pages/SellPage"));
const ViewProductsPage: React.FC = lazy(()=>import("./pages/ViewProductsPage"));
const NotificationBox: React.FC = lazy(()=>import('./components/NotificationBox'));


function App() {
    const { pages } = usePageIsLoaded();
    const { pathname } = useLocation();

    useEffect(() => {
        aos.init();
    }, []);

    return (
        <div className="App">
            <NavigationBar/>
            <SplashPage show={(!pages.find(p => p.pageName === "home")?.loaded && pathname === "/")} />
            <NotificationBox/>
            <Suspense fallback={<BubbleSpinner />} >
                <Routes>
                    <Route index element={<HomePage/>} />
                    <Route path='/category/:category' element={<ViewProductsPage/>} />
                    <Route path={'/category/:category/:product_id'} element={<SeeProductPage />} />
                    <Route path={"/search/:query"} element={<SearchResultsPage />} />
                    <Route path={'/sell/*'} element={<SellPage/>} />
                    <Route path={"/subscriptions"} element={<SubscriptionsPage />} />
                    <Route path={"/my-account/*"} element={<DashboardPage/>} />
                    <Route path={"/settings/*"} element={<SettingsPage />} />
                    <Route path={'/auth/*'} element={<AuthPage/>} />
                    <Route path={'/*'} element={<Four04Page/>} />
                </Routes>
            </Suspense>
            <Footer/>
        </div>
    );
}

export default App;
