import React from 'react'
import { BannerAd } from '../Types/Types'
import usePageIsLoaded from "../hooks/usePageIsLoaded";

const ShowBannerAd = ({ad}:{ ad:BannerAd}) => {
    const { setIsLoaded } = usePageIsLoaded()
    const setLoaded = () => setIsLoaded("home", true);

  return (
    <div  className={`w-full h-max md:h-[60vh] `}>
        <img onLoad={setLoaded} className='w-full h-full object-fit ' src={ad.image} alt="" />
    </div>
  )
}

export default ShowBannerAd