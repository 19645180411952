import {create} from "zustand";
import {RateAndReviewObj, SellerSpecificReviews} from "../Types/Types";
import sendReq from "../utils/axios";


interface useRateAndReviewObj {
    reviews: SellerSpecificReviews[];

    calculateRating: (sellerId: string) => SellerSpecificReviews;
    postReview: (review: RateAndReviewObj) => Promise<string>;
    getSellerReviews: (sellerId: string) => Promise<string>;
    reportSeller: (sellerId: string, complaint: string)=>Promise<string>;
}

const useRateAndReview = create<useRateAndReviewObj>((set, get)=>({
    review: {review: "", sellerId: "", clientName: "", productQuality: {
        rating: 0, review: "" }, sellerService: { rating: 0, review: "" }},
    reviews: [],

    calculateRating: (sellerId)=>{
        let default_ = {
            serviceRating: {
                "5": 0, "4": 0, "3": 0, "2": 0, "1": 0
            }, productRating: {
                "5": 0, "4": 0, "3": 0, "2": 0, "1": 0
            }, reviews: [],
            sellerId: "",
            productId: ""
        }
        let find = get().reviews.find(r => r.sellerId === sellerId);
        if(find) return find
        return default_
    },
    postReview: (review)=>{
        return new Promise(async(resolve, reject)=>{
            if(review.productRating < 1 && review.serviceRating < 1) return reject("Please rate, at least, the service or product of this seller");
            await sendReq.post("/review-seller", review)
                .then(({data})=>resolve(data.message))
                .catch(err => reject(err))
        })
    },
    getSellerReviews: (sellerId)=>{
        return new Promise(async(resolve, reject)=>{

            await sendReq.get(`/get-seller-reviews/${sellerId}`)
                .then(({data})=>{
                    // find if user exists in reviews array
                    let reviews = get().reviews;
                    let seller = reviews.find(rev => rev.sellerId === sellerId)
                    if(!seller)reviews.push(data)
                    else reviews = reviews.map(rev => {
                        if(rev.sellerId === sellerId) return data
                        return rev
                    })
                    set(p => ({...p, reviews: reviews}))
                })
                .catch(err => reject(err))
        })
    },
    reportSeller: (sellerId, complaint)=>{
        return new Promise(async(resolve, reject)=>{
            await sendReq.post("/report-seller", { sellerId: sellerId, complaint: complaint  })
                .then(({data})=>{
                    resolve(data.message)
                })
                .catch(err => reject(err))
        })
    }

}))


export default useRateAndReview;

