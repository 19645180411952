import {Card, Spinner} from "evergreen-ui";
import {ProductObj} from "../Types/Types";
import {comma, fixCategory} from "../utils/functions";
import {propsToShow} from "../utils/data";
import {useNavigate} from "react-router-dom";
import productStore from "../store/productStore";
import {startTransition, useState} from "react";
import useImpression from "../hooks/useImpression";
import useInsight from "../hooks/useInsight";


export default function ShowProduct({product, index}:{product: ProductObj, index:number}){
    const navigate = useNavigate();
    const { setViewProduct } = productStore();
    const [spin, setSpin] = useState(true)
    const { divRef } = useImpression(product._id);
    const { addClick } = useInsight();

    const properties = () => {
        const props = propsToShow.find(p => p.category === product.category);
        if (!props) return [];
        return props.properties.map((prop, i) => {
            let propName = prop.prop;
            let valueObj = product.properties.find(p => {
                if(p.property.toLowerCase() === "year of manufacture" || p.property.toLowerCase() === "y.o.m") {
                    prop.prop = "Y.O.M";
                    p.property = "Y.O.M"
                }
                return p.property === prop.prop;
            });
            if(prop.prop.toLowerCase() === 'location'){
                if(!valueObj?.value){
                    valueObj = { property: "Location", value: product["location"].county + ", "+product["location"].ward}
                }
            }
            if(prop.prop.toLowerCase()==="subcategory"){
                if(!valueObj?.value){
                    valueObj = { property: "Category", value: product[prop.prop] }
                    propName = "Category";
                }
            }
            return {unit: prop.unit, prop: propName, value: valueObj !== undefined ? valueObj.value : product[prop.prop]};
        });
    };


    const goto = () => {
        setViewProduct(product);
        addClick(product._id).catch(err=>{})
        if(window.location.href.includes("search"))return startTransition(()=>navigate(`../category/${fixCategory(product.category as string)}/${product._id}`));
        startTransition(()=>navigate(`./${product._id}`))
    }

    return <Card ref={divRef} onClick={goto} elevation={1} className={"w-full min-w-[150px] h-max rounded-md hover:scale-[1.01] bg-white-500 dark:bg-black-200 dark:!shadow-black-400 dark:hover:bg-black-100 min-h-[40vh] transition-all cursor-pointer"}>
        <div className={"w-full h-max flex"}>
            {spin && <Spinner className={"spinner absolute left-0 right-0 top-0 bottom-0 m-auto"} />}
            <img onLoad={()=>setSpin(false)} src={product.images[0]} alt={""} className={"w-full h-[30vh] bg-white-400 dark:bg-black-300 object-cover"} />
        </div>
        <div className={' w-full h-max p-2 grid grid-flow-row'}>
            <h1 className={"font-bold"}>Ksh.{comma(product.price??0)}</h1>
            <div className={""} />
            <div className={"w-full grid gap-[0.5rem] overflow-hidden auto-cols-max grid-flow-col"}>
                <span className={"grid w-max"}>
                    {properties().map((p, i) => p.value?
                        <p className={" font-bold"} key={i}>
                        {p.prop}</p>:<></>)}
                    <p className={"font-bold"}>Location</p>
                </span>
                <span className={"grid w-3/4 md:w-full truncate "}>
                    {properties().map((p, i)=>p.value ?
                        <p className={"w-full  truncate"} key={i}>
                        {p.value}{p.unit}
                        </p>:<></>)}
                    <p className={"truncate"} >
                        {product.location.ward + ", "+ product.location.county}
                    </p>
                </span>
            </div>
            <p className={"bg-slate-200 dark:bg-blue-300 dark:!text-black-500 w-max pr-2 pl-2 rounded-md text-[12px]"}>{product.properties.find(p => p.property === "Condition") &&
            product.properties.find(p=>p.property === 'Condition')?.value}</p>
    {/*    TODO: write a major element for each category to show*/}
        </div>
    </Card>
}