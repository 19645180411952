import {create} from "zustand";
import React from "react";
import sendReq from "../utils/axios";
import {ProductObj} from "../Types/Types";

interface useSearchObj {
    term: string;
    results: ProductObj[];

    editTerm: (e:React.ChangeEvent<HTMLInputElement>) => void;
    onlyTermSearch: ()=>Promise<void>;
}

const useSearch = create<useSearchObj>((set, get)=>({
    term: "",
    results: [],

    editTerm: (e) => {
        set(p=>({...p, term: e.target.value}))
        setTimeout(()=>{
            get().onlyTermSearch().catch(err => {})
        }, 1500)
    },
    onlyTermSearch: () => {
        return new Promise(async(resolve, reject)=>{
            let term = get().term;
            if(!term)return
            await sendReq.get(`/search/${term}`)
                .then(({data})=>{
                    set(p=>({...p, results: data }))
                    return resolve()
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}))

export default useSearch;

