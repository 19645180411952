import React, {useEffect, useState} from "react";
import {Button, TextareaField} from "evergreen-ui";
import useRateAndReview from "../hooks/useRateAndReview";
import {RateAndReviewObj} from "../Types/Types";
import productStore from "../store/productStore";
import useNotification from "../hooks/useNotification";
import SellerRatingStats from "./SellerRatingStats";
import StarBtn from "./StarBtn";

interface props {
}

export default function WriteReviewAndRate({}: props) {
    const {viewProduct} = productStore()
    const [review, setReview] = useState<RateAndReviewObj>({
        serviceRating: 0,
        productRating: 0,
        review: "",
        sellerId: "",
        productId: "",
    })
    const {showNotification, showError} = useNotification();
    const [spin, setSpin] = useState(false);
    const {postReview} = useRateAndReview();

    useEffect(() => {
        if (viewProduct) setReview(p => ({...p, sellerId: viewProduct.seller.sellerId, productId: viewProduct._id}))
    }, [viewProduct]);

    const submitReview = async () => {
        setSpin(true);
        await postReview(review)
            .then(message => showNotification("Success!", message))
            .catch(err => {
                showError(err)
            })
            .finally(() => setSpin(false))
    }

    return <div className={"grid p-[0.5rem]"}>
        <div className={"grid gap-4 md:grid-cols-2"}>
            <span className={"outline outline-gray-100 dark:outline-gray-800 p-2 rounded-2xl"}>
                <p className={"text-3xl"}>Rate Seller's Product</p>
                <span className={"h-[4rem] grid grid-cols-5"}>
                    {[1, 2, 3, 4, 5].map((star, i) => <StarBtn star={star} count={review.productRating} setCount={
                        () => setReview(p => ({...p, productRating: star}))
                    } key={i}/>)}
                </span>
                <SellerRatingStats sellerId={viewProduct?.seller.sellerId as string}
                                   field={"productRating"}/>
            </span>
            <span className={"outline outline-gray-100 dark:outline-gray-800 p-2 rounded-2xl"}>
                <p className={"text-3xl"}>Rate Seller's Service</p>
                <span className={"h-[4rem] grid grid-cols-5"}>
                    {[1, 2, 3, 4, 5].map((star, i) => <StarBtn star={star} count={review.serviceRating} setCount={
                        () => setReview(p => ({...p, serviceRating: star}))
                    } key={i}/>)}
                </span>
                <SellerRatingStats sellerId={viewProduct?.seller.sellerId ?? ""} field={"serviceRating"}/>
            </span>
        </div>
        <TextareaField value={review.review} onChange={(e:any)=>setReview(p=>({...p, review: e.target.value}))} label={"Review"}/>
        <Button onClick={submitReview} isLoading={spin} disabled={spin} className={"primary-btn"}>
            Submit Review
        </Button>
    </div>
}

