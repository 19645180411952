import sendReq from "../utils/axios";
import {ProductObj} from "../Types/Types";
import productStore from "../store/productStore";


export const useFilters = () => {
    const { harmonize } = productStore();
    const harmonizeResults = async (arr_len:number, category:string): Promise<ProductObj[]> => {
        // fetch more products
        return new Promise(async(resolve, reject)=>{
            await sendReq.get(`/get-products/${arr_len}_${category}`)
                .then(({data})=>{
                    resolve(data)
                })
                .catch(err => reject(err))
        })
    }
    return { harmonizeResults }
}

