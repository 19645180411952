// noinspection ExceptionCaughtLocallyJS

import {create} from "zustand";
import {ProductObj} from "../Types/Types";
import sendReq from "../utils/axios";
import {fixCategory} from "../utils/functions";

interface prodListing {
    category: string;
    products: ProductObj[];
}

interface productStoreObj {
    products: {[key:string]:ProductObj[]};
    viewProduct: ProductObj | null;
    viewList: ProductObj[];

    setList: (list:ProductObj[])=>void;
    setViewProduct: (product:ProductObj | null)=>void;
    getProducts: (category:string)=>Promise<Error>;
    getOneProduct: (productId: string)=>Promise<Error>;
    harmonize: (data: ProductObj[], category: string) => void;
}

const productStore = create<productStoreObj>((set, get)=>({
    products: {},
    viewList: [],
    viewProduct: null,

    setList: (list)=>{
        set(state=>({...state, viewList: list}))
    },
    setViewProduct:(product)=>{
        set(state=>({...state, viewProduct: product}))
    },
    getOneProduct: (productId)=>{
        return new Promise(async(resolve, reject)=>{
            await sendReq.get(`/get-one-product/${productId}`)
                .then(({data})=>{
                    set(state=>({...state, viewProduct: data}));
                })
                .catch(err => reject(err));
        })
    },
    getProducts: (query)=>{
        return new Promise(async(_resolve, reject)=>{
            let category = query && query.split("_")[0] ? "_"+query.split("_")[0]:" ";
            let length = category === " " ? Object.values(get().products).reduce((acc, curr)=>acc+=curr.length, 0):
                get().products[category] ? get().products.length : 0;
            let finalQuery = `${length}${query}`
            if(!finalQuery)finalQuery = ""
            await sendReq.get(`/get-products/${finalQuery}`)
                .then(({data})=>{
                    /** current list of products */
                    get().harmonize(data, query.split("_")[1])
                    // const newProducts = get().products;
                    // if(data.length < 1) {
                    //     newProducts[fixCategory(query.split("_")[1])] = []
                    // }
                    // else for (let product of data) {
                    //     const category = fixCategory(product.category as string);
                    //     if (!newProducts[category]) newProducts[category] = [product];
                    //     else if(!newProducts[category].find(p=>p._id === product._id))newProducts[category].push(product);
                    // }
                    // set(state => ({...state, products: newProducts }));
                })
                .catch(err =>
                    reject(err)
                )
        })
    },
    harmonize: (data, category)=>{
        const newProducts = get().products;
        if(data.length < 1) {
            newProducts[fixCategory(category)] = []
        }
        else for (let product of data) {
            const category = fixCategory(product.category as string);
            if (!newProducts[category]) newProducts[category] = [product];
            else if(!newProducts[category].find(p=>p._id === product._id))newProducts[category].push(product);
        }
        set(state => ({...state, products: newProducts }));
    }
}))

export default productStore;
