import {Button, SearchInput} from "evergreen-ui";
import React, {startTransition, useState} from "react";
import siteData from "../store/siteData";
import useSearch from "../hooks/useSearch";
import {useNavigate} from "react-router-dom";
import useNotification from "../hooks/useNotification";

interface props extends React.HTMLAttributes<HTMLDivElement> {

}

export default function HomeSearchBar({...rest}: props) {
    const {suggestions} = siteData();
    const {term, editTerm, onlyTermSearch, results} = useSearch();
    const {showError} = useNotification();
    const [spin, setSpin] = useState(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false)

    const find = async () => {
        setSpin(true)
        await onlyTermSearch()
            .then(() => {
                startTransition(()=>navigate(`/search/${term}`))
            })
            .catch(err => showError(err))
            .finally(() => {
                setSpin(false)
            })
    }

    return <div className={`home-search-bar block z-[50] bg-sky-300 gap-2 h-max rounded-3xl p-1 shadow-lg ${rest.className}`}>
        <div className={"grid w-full"}>
            <SearchInput list={"search-hints"} value={term} onChange={editTerm}
                     placeholder={"What are you looking for today?"} width={""}
                         // onBlur={()=>setShow(false)}
                         onFocus={()=>term.length > 0 ? setShow(true) : ()=>{}}
                     className={"!h-[2.5rem] !w-[80%] !max-w-none !rounded-3xl !leading-[2.5em]"}/>
        </div>
        <Button onClick={find} isLoading={spin} disabled={spin} className={"primary-btn ml-auto w-[100px]"}>
            Search
        </Button>
        <datalist id={"search-hints"}>
            {suggestions.map((hint, i) => <option key={i}>{hint}</option>)}
        </datalist>
    </div>
}

